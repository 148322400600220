import { useRef } from "react";
import PropTypes from "prop-types";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Tabs, Tab, Button } from "ui";
import { briefListPropTypes } from "features/brief";
import useScroll from "hooks/useScroll";

export default function BriefFilter({ briefs, handleSelectBrief, defaultSelectedId }) {
  const scrollRef = useRef(null);
  const { atEnd, atStart, scrollRight, scrollLeft } = useScroll(scrollRef);

  return (
    <div className="relative flex justify-center items-center">
      <Tabs
        ref={scrollRef}
        size="lg"
        radius="md"
        color="primary"
        variant="light"
        items={briefs}
        onSelectionChange={handleSelectBrief}
        defaultSelectedKey={defaultSelectedId}
        classNames={{
          base: "max-w-full flex justify-center",
          tab: "uppercase h-auto min-w-fit p-0",
          tabContent: "grayscale group-data-[selected=true]:grayscale-0",
          tabList: "gap-3",
          cursor: "rounded-3xl",
        }}
      >
        {(brief) => (
          <Tab
            key={brief.id}
            title={
              <div className="flex h-full min-w-fit">
                <img
                  className="object-cover h-14 sm:h-16 rounded-3xl aspect-4/3"
                  src={brief.thumbnail}
                  alt="organisation icon"
                />
                <div className="flex justify-center items-center flex-col">
                  <span className="text-xl ps-4 pe-5">{brief.organisation?.name}</span>
                </div>
              </div>
            }
          />
        )}
      </Tabs>

      <Button
        isIconOnly
        size="sm"
        color="primary"
        variant="light"
        className={`absolute left-2 h-2/3 backdrop-blur-sm touch-device:invisible ${atStart && "hidden"}`}
        onClick={() => scrollLeft()}
      >
        <ChevronLeft color="inherit" />
      </Button>
      <Button
        isIconOnly
        color="primary"
        variant="light"
        className={`absolute right-2 h-2/3 backdrop-blur-sm touch-device:invisible ${atEnd && "hidden"}`}
        size="sm"
        onClick={() => scrollRight()}
      >
        <ChevronRight />
      </Button>
    </div>
  );
}
BriefFilter.propTypes = {
  briefs: PropTypes.arrayOf(briefListPropTypes).isRequired,
  handleSelectBrief: PropTypes.func.isRequired,
  defaultSelectedId: PropTypes.number.isRequired,
};
