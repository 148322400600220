import { useRef, forwardRef, useEffect, useState } from "react";
import { formatBytes, writeFilesToInput } from "utils/helpers";
import { ReactSortable } from "react-sortablejs";
import { Element } from "react-scroll";
import { Collapse, Form, Button, Tooltip } from "ui";
import useGcsFileUpload from "hooks/useGcsFileUpload";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import FormControl from "forms/FormControl";
import FormFile from "forms/file/FormFile";

const filePropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  file: PropTypes.shape({
    size: PropTypes.number,
  }).isRequired,
  url: PropTypes.string.isRequired,
});

function FileCard({ file, onUpdate, onDelete, error = "" }) {
  const fileDescriptionControlRef = useRef(null);

  return (
    <div className="old-d-flex old-flex-column old-w-100 old-mb-2">
      <div
        className={`old-d-flex old-align-items-center old-p-3 old-rounded-4 old-w-100 old-border old-border-lightgrey ${error ? "old-bg-danger" : "old-bg-offdark"}`}
        onClick={() => fileDescriptionControlRef.current.focus()}
        role="button"
        tabIndex={0}
        data-testid="multi-file-card"
      >
        <Tooltip content="Download File" placement="right">
          <a download href={file.url} onClick={(e) => e.stopPropagation()}>
            <SimCardDownloadIcon className="!size-8 old-text-white" />
          </a>
        </Tooltip>

        <div className="old-w-100 old-px-3">
          <div className="old-d-flex old-align-items-center old-w-100">
            <EditIcon className="old-text-white !size-5 old-me-2" />

            <FormControl
              className="old-bg-transparent old-text-white old-w-100 old-border-0 old-px-0"
              wrapperClassName="old-mb-0 old-w-100"
              placeholder={file.name}
              defaultValue={file?.description || file.name}
              onUpdate={onUpdate}
              ref={fileDescriptionControlRef}
              data-testid="multi-file-card-name"
            />
          </div>

          {file.file?.size && (
            <small className="font-roman old-text-lightgrey" data-testid="multi-file-card-size">
              {formatBytes(file.file.size)}
            </small>
          )}
        </div>

        <CloseIcon
          className="!size-5 old-rounded-circle old-text-white"
          role="button"
          onClick={onDelete}
        />
      </div>

      {error && (
        <span className="old-py-2 old-d-flex old-align-items-center old-text-danger">
          <ErrorOutlineIcon className="old-me-2" />
          {error}
        </span>
      )}
    </div>
  );
}

FileCard.propTypes = {
  file: filePropType.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  error: PropTypes.string,
};

const FormMultiFile = forwardRef(
  (
    {
      name,
      maxFiles,
      label,
      fileType,
      defaultValue,
      required,
      className,
      labelClassName,
      wrapperClassName,
      fileError,
      ...fileProps
    },
    ref,
  ) => {
    const { files, dispatch, handleFileUpload, handleCancelFileUpload } = useGcsFileUpload(
      fileType,
      null,
      defaultValue,
    );
    const [open, setOpen] = useState(required);
    const fileRef = useRef(null);

    useEffect(() => {
      writeFilesToInput(
        files.map((f) => f.file),
        fileRef.current,
      );

      if (ref) ref.current = files;
    }, [files]);

    const handleUpdateFileDescription = (index, description) => {
      if (description === files[index].name) return;

      dispatch({ type: "UPDATE", payload: { index, update: { description } } });
    };

    const toggleOpen = () => {
      if (required || files.length > 0) return;
      setOpen(!open);
    };

    return (
      <div className={wrapperClassName}>
        <div
          className={`old-d-flex old-align-items-center old-justify-content-between old-mb-3 ${!required ? "old-bg-offprimary old-p-3 old-rounded-4" : ""}`}
        >
          <Form.Label className={`old-text-start old-mb-0 ${labelClassName}`}>
            {label} {!required && "(optional)"}
          </Form.Label>

          {files.length <= 0 && !required && (
            <Button onClick={toggleOpen} color={open ? "primary" : "success"}>
              {!open ? "Add File" : "Close"}
            </Button>
          )}
        </div>

        <Collapse isOpen={open}>
          <div id="collapse">
            <ReactSortable
              list={files}
              setList={(newFiles) => dispatch({ payload: newFiles.map(({ chosen, ...f }) => f) })}
              animation={200}
              className={files.length > 0 ? "old-mb-3" : "0"}
            >
              {files.map((file, index) => (
                <div className="old-d-flex old-align-items-center" key={file.file_id || file.id}>
                  <Element name={String(file.file_id)} />
                  <DragIndicatorIcon />
                  <FileCard
                    file={file}
                    onUpdate={(description) => handleUpdateFileDescription(index, description)}
                    onDelete={() => dispatch({ type: "REMOVE", payload: file.file_id })}
                    error={fileError(file.file_id)}
                  />
                </div>
              ))}
            </ReactSortable>

            <FormFile.Dropzone
              name={name}
              aria-label={name}
              wrapperClassName={`old-mb-0 old-w-100 pb-10 ${files.length >= maxFiles ? "old-d-none" : "old-d-block"}`}
              onUpdate={handleFileUpload}
              onCancel={handleCancelFileUpload}
              ref={fileRef}
              required={required}
              className={className}
              {...fileProps}
            />
          </div>
        </Collapse>
      </div>
    );
  },
);

FormMultiFile.propTypes = {
  name: PropTypes.string.isRequired,
  maxFiles: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  fileType: PropTypes.oneOf(["technical", "image"]).isRequired,
  fileError: PropTypes.func.isRequired,
  defaultValue: PropTypes.arrayOf(filePropType),
  required: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

FormMultiFile.defaultProps = {
  defaultValue: [],
  required: true,
  className: "border-2 rounded-3xl border-foreground bg-content2 hover:bg-default-focus",
  labelClassName: "uppercase font-bold",
  wrapperClassName: "old-mb-5",
};

export default FormMultiFile;
