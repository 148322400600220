import { object, string, mixed, number, boolean, date, addMethod, array } from "yup";
import iso3311a2 from "iso-3166-1-alpha-2";
import {
  validateFileDimensions,
  validateFileSize,
  validateFileRequired,
  validateFileTypes,
  validateDateTime,
  validateDateTimeAfter,
} from "utils/validation/validationMethods";

addMethod(mixed, "fileDimensions", validateFileDimensions);
addMethod(mixed, "fileSize", validateFileSize);
addMethod(mixed, "requiredFile", validateFileRequired);
addMethod(mixed, "fileTypes", validateFileTypes);
addMethod(string, "dateTime", validateDateTime);
addMethod(string, "dateTimeAfter", validateDateTimeAfter);

const usernameSchema = string()
  .min(1)
  .max(20)
  .matches(/^\w+$/, "Username can only contain letters, numbers and underscores");
const countrySchema = mixed().oneOf(Object.keys(iso3311a2.getData()), "Not a valid country");

const enterBriefSchema = object().shape({
  published: boolean(),
  require_working: boolean(),
  title: string().when("published", {
    is: true,
    then: string().required(),
    otherwise: string(),
  }),
  thumbnail: mixed().fileTypes().fileSize(26214400),
  thumbnailUrl: string().when("published", {
    is: true,
    then: string().nullable().required("Thumbnail is a required field"),
    otherwise: string().nullable(),
  }),
  images: mixed().fileTypes().fileSize(26214400),
  publishedImages: array().when("published", {
    is: true,
    then: array().min(1, "Images is a required field").required(),
    otherwise: array(),
  }),
  technical_file: mixed(),
  publishedTechnical_file: array().when(["published", "require_working"], {
    is: true,
    then: array().min(1, "Working file is a required field").required(),
    otherwise: array(),
  }),
  description: string().when("published", {
    is: true,
    then: string().required(),
    otherwise: string(),
  }),
});

const userOnboardingAvatarSchema = object().shape({
  icon: mixed().fileTypes().fileSize(26214400),
  iconUrl: string().nullable().required("Icon is a required field"),
});

const userOnboardingSchema = object().shape({
  given_name: string().strict().required("First Name is a required field"),
  family_name: string().strict().required("Last Name is a required field"),
  dob: date().max(new Date(), "Date of birth cannot be in the future").required(),
  gender: string().required("Gender is a required field"),
  country_code: countrySchema.required(),
});

const updateBriefTypeSchema = object().shape({
  private: boolean(),
  start_time: string()
    .required("Start Date is a required field")
    .dateTime("YYYY-MM-DD HH:mm", "Start Date is not a valid format"),
  proposal_deadline: string().when("private", {
    is: true,
    then: string()
      .required("Proposal Deadline is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Proposal Deadline is not a valid format")
      .dateTimeAfter("start_time", "Proposal Deadline must be after the start time."),
    otherwise: string(),
  }),
  proposal_selected: string().when("private", {
    is: true,
    then: string()
      .required("Proposal Selected is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Proposal Selected is not a valid format")
      .dateTimeAfter("proposal_deadline", "Proposal Selected must be after the proposal deadline."),
    otherwise: string(),
  }),
  submission_deadline: string().when("private", {
    is: true,
    then: string()
      .required("Submission Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Submission Deadline is not a valid format")
      .dateTimeAfter(
        "proposal_selected",
        "Submission Deadline must be after the proposal selected.",
      ),
    otherwise: string()
      .required("Submission Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Submission Deadline is not a valid format")
      .dateTimeAfter("start_time", "Submission Deadline must be after the start time."),
  }),
  voting_deadline: string().when("private", {
    is: false,
    then: string()
      .required("Voting Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Voting Deadline is not a valid format")
      .dateTimeAfter(
        "submission_deadline",
        "Voting Deadline must be after the submission deadline.",
      ),
    otherwise: string(),
  }),
  winners_selected: string().when("private", {
    is: true,
    then: string()
      .required("Winners Selected Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Winners Selected is not a valid format")
      .dateTimeAfter(
        "submission_deadline",
        "Winners Selected must be after the submission deadline.",
      ),
    otherwise: string()
      .required("Winners Selected Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Winners Selected is not a valid format")
      .dateTimeAfter("voting_deadline", "Winners Selected must be after the voting deadline."),
  }),
});

const createBriefInformationSchema = object().shape({
  private: boolean(),
  brief_image: mixed().fileTypes().fileDimensions(2880, 2160),
  publishedBriefImage: string().nullable().required("Brief Image is a required field"),
  title: string().required(),
  short_description: string().max(140).required("Short Description is a required field"),
  timezone: string().max(50).required(),
  description: string().max(1500).required(),
  // We use strings here to use custom validation against moment times (which are more robust)
  start_time: string()
    .required("Start Date is a required field")
    .dateTime("YYYY-MM-DD HH:mm", "Start Date is not a valid format"),
  proposal_deadline: string().when("private", {
    is: true,
    then: string()
      .required("Proposal Deadline is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Proposal Deadline is not a valid format")
      .dateTimeAfter("start_time", "Proposal Deadline must be after the start time."),
    otherwise: string(),
  }),
  proposal_selected: string().when("private", {
    is: true,
    then: string()
      .required("Proposal Selected is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Proposal Selected is not a valid format")
      .dateTimeAfter("proposal_deadline", "Proposal Selected must be after the proposal deadline."),
    otherwise: string(),
  }),
  submission_deadline: string().when("private", {
    is: true,
    then: string()
      .required("Submission Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Submission Deadline is not a valid format")
      .dateTimeAfter(
        "proposal_selected",
        "Submission Deadline must be after the proposal selected.",
      ),
    otherwise: string()
      .required("Submission Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Submission Deadline is not a valid format")
      .dateTimeAfter("start_time", "Submission Deadline must be after the start time."),
  }),
  voting_deadline: string().when("private", {
    is: false,
    then: string()
      .required("Voting Deadline Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Voting Deadline is not a valid format")
      .dateTimeAfter(
        "submission_deadline",
        "Voting Deadline must be after the submission deadline.",
      ),
    otherwise: string(),
  }),
  winners_selected: string().when("private", {
    is: true,
    then: string()
      .required("Winners Selected Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Winners Selected is not a valid format")
      .dateTimeAfter(
        "submission_deadline",
        "Winners Selected must be after the submission deadline.",
      ),
    otherwise: string()
      .required("Winners Selected Date is a required field")
      .dateTime("YYYY-MM-DD HH:mm", "Winners Selected is not a valid format")
      .dateTimeAfter("voting_deadline", "Winners Selected must be after the voting deadline."),
  }),
  resources: mixed(),
});

const createBriefIncentivesSchema = object().shape({
  prize_money: number()
    .typeError("Prize Money must be a number")
    .nullable()
    .moreThan(0, "Prize Money cannot be negative or 0")
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  currency: string().when("prize_money", (prizeMoney, schema) => {
    if (prizeMoney > 0)
      return schema.required("Currency must be supplied if prize money is supplied.");

    return schema;
  }),
  other_incentives: string().max(1000).required("Other Incentives is a required field"),
  things_to_consider: string().max(1000).required("Things to Consider is a required field"),
  selection_criteria: string().max(1000).required("Selection Criteria is a required field"),
});

const createPlaylistSchema = object().shape({
  title: string().required(),
  description: string(),
  briefs: array().of(number()).min(1, "There must be at least 1 brief in a playlist").required(),
});

const userEditSchema = object().shape({
  given_name: string(),
  family_name: string(),
  username: usernameSchema.required("Username is a required field"),
  description: string(),
  dob: date().max(new Date(), "Date of birth cannot be in the future"),
  gender: string(),
  country_code: countrySchema,
});

const orgEditSchema = object().shape({
  name: string().required(),
  website: string().required(),
  description: string(),
});

const reportSchema = object().shape({
  reason: string().oneOf(["on"]).required("You must select a reason"),
  details: string(),
  show_url: boolean(),
  url: string().when("show_url", {
    is: true,
    then: string().required(),
  }),
});

const issueSchema = object().shape({
  reason: string().required(),
  show_page: boolean(),
  page: string().when("show_page", {
    is: true,
    then: string().required(),
  }),
  details: string().required(),
  api: string(),
});

const briefNotInterestedSchema = object().shape({
  reason: string().oneOf(["on"]).required("You must select a reason"),
});

const handleSchema = object().shape({
  handle: string().min(1).matches(/^[^@]/, "Handle first letter cannot be @").required(),
});

export {
  usernameSchema,
  handleSchema,
  enterBriefSchema,
  userOnboardingSchema,
  userOnboardingAvatarSchema,
  updateBriefTypeSchema,
  countrySchema,
  createBriefInformationSchema,
  createBriefIncentivesSchema,
  createPlaylistSchema,
  userEditSchema,
  orgEditSchema,
  reportSchema,
  issueSchema,
  briefNotInterestedSchema,
};
