import { useState, forwardRef } from "react";
import { useProtectedAction } from "features/authentication";
import PropTypes from "prop-types";

const ModalClickContainer = forwardRef(
  ({ modal, protectedAction, modalProps, as, children, ...props }, ref) => {
    const [show, setShow] = useState(false);
    const [handleOpenProtected] = useProtectedAction(() => setShow(true));
    const Modal = modal;
    const As = as;

    const handleOpenModal = (e) => {
      if (show) return;

      e.stopPropagation();
      e.preventDefault();

      if (protectedAction) {
        handleOpenProtected();
        return;
      }

      setShow(true);
    };

    return (
      <>
        <Modal show={show} onHide={() => setShow(false)} {...modalProps} />

        <As {...props} onClick={handleOpenModal} ref={ref} role="button" tabIndex={0}>
          {children}
        </As>
      </>
    );
  },
);
ModalClickContainer.propTypes = {
  modal: PropTypes.elementType.isRequired,
  protectedAction: PropTypes.bool,
  modalProps: PropTypes.shape({}),
  as: PropTypes.elementType,
  children: PropTypes.node.isRequired,
};
ModalClickContainer.defaultProps = {
  protectedAction: false,
  modalProps: {},
  as: "div",
};

export default ModalClickContainer;
