import { useMemo, useState, useContext } from "react";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "features/user";
import { DocumentMetaContext } from "features/navigation";
import { tv } from "tailwind-variants";
import { twMerge } from "tailwind-merge";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import LinkIcon from "icons/LinkIcon";
import TwitterIcon from "icons/TwitterIcon";
import FacebookIcon from "icons/FacebookIcon";
import WhatsappIcon from "icons/WhatsappIcon";
import Tooltip from "ui/popups/Tooltip";
import Mixpanel from "adapters/mixpanel";
import Button from "ui/buttons/Button";
import PropTypes from "prop-types";

const shareTypes = {
  facebook: {
    button: FacebookShareButton,
    icon: FacebookIcon,
  },
  twitter: {
    button: TwitterShareButton,
    icon: TwitterIcon,
  },
  whatsapp: {
    button: WhatsappShareButton,
    icon: WhatsappIcon,
  },
};

const shareButton = tv({
  variants: {
    direction: {
      vertical: "flex flex-col gap-y-2",
      horizontal: "flex flex-row gap-x-2",
    },
  },
});

export default function ShareButton({
  direction = "vertical",
  title = "",
  url = "",
  trackingLocation = "",
  className = "",
  types = ["facebook", "twitter", "whatsapp"],
  classNames = {},
  tooltipProps = {},
  ...buttonProps
}) {
  const [shareClicked, setShareClicked] = useState(false);
  const [searchParams] = useSearchParams();
  const documentMetaContext = useContext(DocumentMetaContext);
  const { user } = useContext(UserContext);

  const urlWithTracking = useMemo(() => {
    const shareUrl = `${url ? window.location.origin + url : documentMetaContext?.url}?${searchParams.toString()}`;

    if (!user.doesSessionExist) return shareUrl;

    return `${shareUrl}${searchParams.length > 0 ? "&" : "?"}utm_source=referral&utm_content=${user.auth0}`;
  }, [user]);

  const handleTrackOnClick = (e, trackingName, cb) => {
    e.stopPropagation();
    Mixpanel.trackButton("Share Button", trackingName, trackingLocation, {
      "Share URL": urlWithTracking,
    });

    if (cb) cb();
  };

  const copyLink = () => {
    navigator.clipboard.writeText(urlWithTracking);
    setShareClicked(true);

    // Reset back after a fixed time
    setTimeout(() => {
      setShareClicked(false);
    }, 2000);
  };

  return (
    <div className={twMerge(shareButton({ direction }), classNames?.wrapper)} data-slot="wrapper">
      {types.map((type) => {
        if (!(type in shareTypes)) return null;

        const ShareButtonComponent = shareTypes[type].button;
        const ShareIcon = shareTypes[type].icon;

        return (
          <Tooltip content={type} placement="left" {...tooltipProps}>
            <Button
              size="sm"
              isIconOnly
              className={twMerge(className, classNames?.base)}
              {...buttonProps}
            >
              <ShareButtonComponent
                url={urlWithTracking}
                title={title || documentMetaContext?.description}
                onClick={(e) => handleTrackOnClick(e, type)}
              >
                <ShareIcon className="!size-5" />
              </ShareButtonComponent>
            </Button>
          </Tooltip>
        );
      })}

      <Tooltip
        content={shareClicked ? "Link Copied" : "Copy Link"}
        placement="left"
        {...tooltipProps}
      >
        <Button
          isIconOnly
          size="sm"
          onClick={(e) => {
            handleTrackOnClick(e, "link", copyLink);
          }}
          className={twMerge(className, classNames?.base)}
          {...buttonProps}
        >
          {shareClicked ? (
            <VerifiedOutlinedIcon className="!size-5" />
          ) : (
            <LinkIcon className="!size-5" />
          )}
        </Button>
      </Tooltip>
    </div>
  );
}
ShareButton.propTypes = {
  direction: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  trackingLocation: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    wrapper: PropTypes.string,
  }),
  tooltipProps: PropTypes.shape({}),
};
