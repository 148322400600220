import { useState, useContext } from "react";
import { UserContext } from "features/user";
import { useBriefs } from "features/brief";
import { Container, Swiper } from "ui";
import SmallBriefCard from "features/brief/components/card/SmallBriefCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function BriefSwiper() {
  const { user } = useContext(UserContext);
  if (!user.doesSessionExist) return null;
  const [toggleHide, setToggleHide] = useState(false);
  const inProgressBriefs = useBriefs({
    user: user.username,
    status: ["proposing", "selecting", "submitting", "voting", "judging", "completed"],
  });

  return (
    inProgressBriefs.all.length > 0 && (
      <Container
        id="your-briefs"
        role="button"
        tabIndex={0}
        onClick={() => setToggleHide(!toggleHide)}
      >
        <div className="select-none bg-content4 text-primary-foreground py-4 rounded-3xl">
          <div className="mx-4 flex justify-between items-center">
            <span className="old-mx-2 uppercase text-xl sm:text-2xl">
              {`Your Briefs (${inProgressBriefs.total})`}
            </span>
            <KeyboardArrowDownIcon className={`!size-10 ${!toggleHide ? "old-rotate-180" : ""}`} />
          </div>
          {!toggleHide && (
            <div className="mt-4">
              <Swiper>
                {inProgressBriefs.all
                  .sort((a, b) => a.submitted - b.submitted)
                  .map((brief) => (
                    <SmallBriefCard entry={brief} trackingLocation="your briefs" />
                  ))}
              </Swiper>
            </div>
          )}
        </div>
      </Container>
    )
  );
}
