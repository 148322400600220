import { twMerge } from "tailwind-merge";
import Button from "ui/buttons/Button";
import Tooltip from "ui/popups/Tooltip";
import Mixpanel from "adapters/mixpanel";
import PropTypes from "prop-types";

export default function StickyActionButton({
  tooltip,
  icon,
  title = "",
  onClick = undefined,
  trackingName = "",
  trackingLocation = "",
  className = "",
  classNames = {},
  tooltipProps = {},
  iconProps = {},
  children = undefined,
  ...buttonProps
}) {
  const Icon = icon;

  const handleTrackOnClick = (e) => {
    Mixpanel.trackButton("Action Button", trackingName, trackingLocation);

    if (onClick) onClick(e);
  };

  return (
    <>
      <div className={classNames?.wrapper || ""} data-slot="wrapper">
        <Tooltip placement="left" content={tooltip} {...tooltipProps}>
          <Button
            isIconOnly
            onClick={handleTrackOnClick}
            className={twMerge(className, classNames?.base)}
            {...buttonProps}
          >
            <Icon {...iconProps} />
          </Button>
        </Tooltip>

        {children}
      </div>

      {title && (
        <p className="font-roman old-fs-10 old-mt-2 old-lh-sm old-text-reset old-d-block text-center">
          {title}
        </p>
      )}
    </>
  );
}
StickyActionButton.propTypes = {
  title: PropTypes.node.isRequired,
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func,
  trackingName: PropTypes.string,
  trackingLocation: PropTypes.string,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    wrapper: PropTypes.string,
    base: PropTypes.string,
  }),
  iconProps: PropTypes.shape({}),
  tooltipProps: PropTypes.shape({}),
  children: PropTypes.node,
};
