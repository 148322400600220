import Row from "ui/layout/Row";
import Col from "ui/layout/Col";
import PropTypes from "prop-types";
import useKeyGen from "hooks/useKeyGen";

const defaultSizing = {
  md: 6,
  xl: 4,
  xxxl: 3,
};

/**
 * @function {component}:   CardGrid
 * @description:            Given an array of entries, create a grid of rows and columns to display the entries in cards
 *
 * @param {String} name:                The name of the display to be shown above
 * @param {Function{component}} card:   The Card component to display
 * @param {Array} entries:              An array of entry objects to display
 * @param {Obejct} sizing:              An object of the display size and number of cols to be displayed on the size
 * @param {Object} cardProps:           Additional props for the Card component
 */
export default function CardGrid({
  card,
  name = "",
  entries = [],
  sizing = defaultSizing,
  ...cardProps
}) {
  const keyGen = useKeyGen();
  const Card = card;

  if (entries.length <= 0) return null;

  return (
    <div className="old-mb-3" data-testid="image-card-display">
      {name && <h3 className="old-mb-0">{name}</h3>}

      <Row>
        {entries.map((entry, index) => (
          <Col
            key={keyGen.getKey(entry)}
            {...sizing}
            className="old-my-3 old-transition-slide-arrow"
          >
            <Card entry={entry} key={entry.id} id={index} {...cardProps} />
          </Col>
        ))}
      </Row>
    </div>
  );
}

CardGrid.propTypes = {
  card: PropTypes.elementType.isRequired,
  name: PropTypes.string,
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  sizing: PropTypes.objectOf(PropTypes.number),
};
