import { Tooltip } from "ui";
import VerifiedBadge from "icons/VerifiedBadge";
import P100Icon from "icons/P100Icon";
import PropTypes from "prop-types";

const badges = {
  blue_badge: {
    component: VerifiedBadge,
    tooltip: "Founding Member",
    color: "#09f",
  },
  p100_engineer: {
    component: P100Icon,
    tooltip: "Oditi Engineer",
  },
  green_badge: {
    component: VerifiedBadge,
    tooltip: "Entered 2+ Briefs",
    color: "#037F22",
  },
  purple_badge: {
    component: VerifiedBadge,
    tooltip: "Veteran (Entered 5+ Briefs)",
    color: "#8245ff",
  },
};

export default function UserBadge({ badgeKey, className = "" }) {
  if (!badgeKey || !Object.prototype.hasOwnProperty.call(badges, badgeKey)) return null;

  const BadgeComponent = badges[badgeKey].component;
  const badgeTooltip = badges[badgeKey].tooltip;
  const badgeColor = badges[badgeKey].color || "";

  return (
    <Tooltip content={badgeTooltip} placement="right">
      <div className="inline-block">
        <BadgeComponent className={className} fill={badgeColor} />
      </div>
    </Tooltip>
  );
}
UserBadge.propTypes = {
  badgeKey: PropTypes.string.isRequired,
  className: PropTypes.string,
};
