import { useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { BriefCard, useBriefs } from "features/brief";
import { PageLayout } from "features/navigation";
import { SubmissionVoteBlock } from "features/submission";
import {
  Container,
  Row,
  Col,
  ScrollToTopButton,
  CardGrid,
  AutoScrollLoader,
  Tabs,
  Tab,
  Button,
} from "ui";
import CategoryIcon from "@mui/icons-material/Category";
import BrushIcon from "@mui/icons-material/Brush";
import GestureIcon from "@mui/icons-material/Gesture";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import LabelIcon from "@mui/icons-material/Label";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import PaletteIcon from "@mui/icons-material/Palette";
import FactoryIcon from "@mui/icons-material/Factory";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import PropTypes from "prop-types";
import useScroll from "hooks/useScroll";
import BriefSwiper from "default/pages/Briefs/BriefSwiper";

const categories = {
  all: { label: "All Briefs", icon: CategoryIcon },
  graphic_design: { label: "Graphic Design", icon: BrushIcon },
  illustration: { label: "Illustration", icon: GestureIcon },
  branding: { label: "Branding", icon: LabelIcon },
  fashion: { label: "Fashion", icon: CheckroomIcon },
  art: { label: "Art", icon: PaletteIcon },
  industrial: { label: "Industrial", icon: FactoryIcon },
  typography: { label: "Typography", icon: TextFieldsIcon },
};

function CategoryToggles({ handleSelectCategory, defaultSelectedKey = "all" }) {
  const scrollRef = useRef(null);
  const { atEnd, atStart, scrollRight, scrollLeft } = useScroll(scrollRef);

  return (
    <div className="relative flex justify-center items-center">
      <Tabs
        ref={scrollRef}
        size="lg"
        radius="full"
        color="primary"
        items={Object.entries(categories)}
        onSelectionChange={handleSelectCategory}
        defaultSelectedKey={defaultSelectedKey}
        fullWidth
        classNames={{
          base: "max-w-full flex justify-center",
          tab: "uppercase h-auto sm:py-2",
          tabList: "p-2",
        }}
      >
        {([category, { label, icon: Icon }]) => (
          <Tab
            key={category}
            title={
              <div className="flex items-center p-2">
                <Icon className="!size-5 old-me-2" />
                <span>{label}</span>
              </div>
            }
          />
        )}
      </Tabs>

      <Button
        isIconOnly
        size="sm"
        color="primary"
        variant="light"
        className={`absolute left-2 h-2/3 backdrop-blur-sm touch-device:invisible ${atStart && "hidden"}`}
        onClick={() => scrollLeft()}
      >
        <ChevronLeft color="inherit" />
      </Button>
      <Button
        isIconOnly
        size="sm"
        color="primary"
        variant="light"
        className={`absolute right-2 h-2/3 backdrop-blur-sm touch-device:invisible ${atEnd && "hidden"}`}
        onClick={() => scrollRight()}
      >
        <ChevronRight />
      </Button>
    </div>
  );
}

CategoryToggles.propTypes = {
  handleSelectCategory: PropTypes.func.isRequired,
  defaultSelectedKey: PropTypes.string,
};

export default function Briefs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const briefs = useBriefs({
    status: null,
    search: searchParams.get("search") || "",
    design_interests: searchParams.get("category") || [],
  });

  return (
    <PageLayout
      pageName="Briefs"
      showFooter={!briefs.hasNextPage}
      navbarProps={{
        size: "lg",
      }}
    >
      <BriefSwiper />
      <Container id="briefs" className="mt-4 old-sb-6 old-overflow-hidden">
        <CategoryToggles
          handleSelectCategory={(id) => setSearchParams(id !== "all" ? { category: id } : {})}
          defaultSelectedKey={searchParams.get("category") || "all"}
        />

        <Row>
          <Col>
            <CardGrid entries={briefs.all.slice(0, 12)} card={BriefCard} variant="primary-100" />

            {briefs.all.length > 0 && (
              <Container className="bg-content2 old-rounded-4 old-mb-3 old-py-3 old-py-md-6">
                <SubmissionVoteBlock color="primary" />
              </Container>
            )}

            <CardGrid entries={briefs.all.slice(12)} card={BriefCard} variant="primary-100" />
          </Col>
        </Row>
        <AutoScrollLoader
          fetchNextPage={briefs.fetchNextPage}
          hasNextPage={briefs.hasNextPage}
          isLoading={briefs.isLoading || briefs.isFetchingNextPage}
          error={briefs.error}
          empty={!briefs.all.length}
          className="old-mb-5"
          errorButtonVariant="black"
        >
          There are no briefs here at the moment.
          <br />
          Please come back at a later date.
        </AutoScrollLoader>
      </Container>

      <ScrollToTopButton />
    </PageLayout>
  );
}
