import { Element } from "react-scroll";
import { Form, Tooltip } from "ui";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PropTypes from "prop-types";

export default function FormWrapper({
  children,
  name = "",
  label = "",
  error = "",
  promptText = "",
  labelClassName = "uppercase font-bold",
  wrapperClassName = "old-mb-5",
}) {
  return (
    <Form.Group
      controlId={name}
      className={`old-form-wrapper old-position-relative ${error ? "old-is-invalid" : ""} ${wrapperClassName}`}
      data-testid={`form-${name}-wrapper`}
    >
      {/* This allows us to scroll to the form component */}
      <Element name={name} />

      <div className="old-position-relative">
        {label !== "" && (
          <Form.Label className={`old-text-start ${labelClassName}`}>{label}</Form.Label>
        )}

        {children}
      </div>

      <Form.Control.Feedback
        className={`old-invalid-feedback font-roman old-align-items-center old-d-${error ? "flex" : "none"}`}
        data-testid={`form-${name}-error`}
      >
        <ErrorOutlineIcon className="!size-5 old-me-1" />
        {error}
      </Form.Control.Feedback>

      {promptText !== "" && (
        <Tooltip placement="right" content={promptText} className="max-w-60 p-3">
          <div className="old-form-promp-text old-position-absolute old-top-0 old-start-0 old-p-0 old-mt-1">
            <InfoOutlinedIcon className="!size-5" />
          </div>
        </Tooltip>
      )}
    </Form.Group>
  );
}

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  promptText: PropTypes.string,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export const getWrapperProps = (inputProps) => {
  const { wrapperClassName, labelClassName, error, label, promptText, ...props } = inputProps;
  return [
    {
      wrapperClassName,
      labelClassName,
      error,
      label,
      promptText,
      name: props.name,
    },
    props,
  ];
};
