/*
    ---------------------------------
    Bootstrap-select.js taken from the react-bootstrap-select-dropdown package
    Credit - https://github.com/hardik-kondhiya/react-bootstrap-select-dropdown
    Developed by Hardik Kondhiya <sonihardik90@gmail.com>

    This file extends the functionality to allow for ref forwarding and props on the dropdown.
*/

const _react = _interopRequireWildcard(require("react"));
require("./bootstrap-select.css");

const _excluded = [
  "error",
  "disabled",
  "showSearch",
  "showAction",
  "showTick",
  "isMultiSelect",
  "selectStyle",
  "actionBtnStyle",
  "menuSize",
  "className",
  "defaultOptions",
  "options",
  "placeholder",
  "showCountOn",
  "onChange",
  "onClose",
];
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  const cacheBabelInterop = new WeakMap();
  const cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) return obj;

  if (obj === null || (_typeof(obj) !== "object" && typeof obj !== "function"))
    return { default: obj };

  const cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) return cache.get(obj);

  const newObj = {};
  const hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (const key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      const desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) Object.defineProperty(newObj, key, desc);
      else newObj[key] = obj[key];
    }
  }
  newObj.default = obj;
  if (cache) cache.set(obj, newObj);

  return newObj;
}
function _extends() {
  _extends = Object.assign
    ? Object.assign.bind()
    : function (target) {
        for (let i = 1; i < arguments.length; i++) {
          const source = arguments[i];
          for (const key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) target[key] = source[key];
          }
        }
        return target;
      };
  return _extends.apply(this, arguments);
}
function _typeof(obj) {
  "@babel/helpers - typeof";

  return (
    (_typeof =
      typeof Symbol === "function" && typeof Symbol.iterator === "symbol"
        ? function (obj) {
            return typeof obj;
          }
        : function (obj) {
            return obj &&
              typeof Symbol === "function" &&
              obj.constructor === Symbol &&
              obj !== Symbol.prototype
              ? "symbol"
              : typeof obj;
          }),
    _typeof(obj)
  );
}
function _toConsumableArray(arr) {
  return (
    _arrayWithoutHoles(arr) ||
    _iterableToArray(arr) ||
    _unsupportedIterableToArray(arr) ||
    _nonIterableSpread()
  );
}
function _nonIterableSpread() {
  throw new TypeError(
    "Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.",
  );
}
function _iterableToArray(iter) {
  if (
    (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null) ||
    iter["@@iterator"] != null
  )
    return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}
function _slicedToArray(arr, i) {
  return (
    _arrayWithHoles(arr) ||
    _iterableToArrayLimit(arr, i) ||
    _unsupportedIterableToArray(arr, i) ||
    _nonIterableRest()
  );
}
function _nonIterableRest() {
  throw new TypeError(
    "Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.",
  );
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  let n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;
  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}
function _iterableToArrayLimit(arr, i) {
  let _i =
    arr == null
      ? null
      : (typeof Symbol !== "undefined" && arr[Symbol.iterator]) || arr["@@iterator"];
  if (_i == null) return;
  const _arr = [];
  let _n = true;
  let _d = false;
  let _s;
  let _e;
  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);
      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i.return != null) _i.return();
    } finally {
      if (_d) throw _e;
    }
  }
  return _arr;
}
function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}
function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  const target = _objectWithoutPropertiesLoose(source, excluded);
  let key;
  let i;
  if (Object.getOwnPropertySymbols) {
    const sourceSymbolKeys = Object.getOwnPropertySymbols(source);
    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }
  return target;
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  const target = {};
  const sourceKeys = Object.keys(source);
  let key;
  let i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }
  return target;
}
const BootstrapSelect = _react.forwardRef((_ref, ref) => {
  const _ref$error = _ref.error;
  const error = _ref$error === void 0 ? false : _ref$error;
  const _ref$disabled = _ref.disabled;
  const disabled = _ref$disabled === void 0 ? false : _ref$disabled;
  const _ref$showSearch = _ref.showSearch;
  const showSearch = _ref$showSearch === void 0 ? false : _ref$showSearch;
  const _ref$showAction = _ref.showAction;
  const showAction = _ref$showAction === void 0 ? false : _ref$showAction;
  const _ref$showTick = _ref.showTick;
  const showTick = _ref$showTick === void 0 ? false : _ref$showTick;
  const _ref$isMultiSelect = _ref.isMultiSelect;
  const isMultiSelect = _ref$isMultiSelect === void 0 ? false : _ref$isMultiSelect;
  const _ref$selectStyle = _ref.selectStyle;
  const selectStyle = _ref$selectStyle === void 0 ? "old-btn-light" : _ref$selectStyle;
  const _ref$actionBtnStyle = _ref.actionBtnStyle;
  const actionBtnStyle =
    _ref$actionBtnStyle === void 0 ? "old-btn-outline-dark" : _ref$actionBtnStyle;
  const _ref$menuSize = _ref.menuSize;
  const menuSize = _ref$menuSize === void 0 ? "auto" : _ref$menuSize;
  const _ref$className = _ref.className;
  const className = _ref$className === void 0 ? "" : _ref$className;
  const _ref$defaultOptions = _ref.defaultOptions;
  const defaultOptions = _ref$defaultOptions === void 0 ? [] : _ref$defaultOptions;
  const { options } = _ref;
  const { placeholder } = _ref;
  const { showCountOn } = _ref;
  const { onChange } = _ref;
  const { onClose } = _ref;
  const props = _objectWithoutProperties(_ref, _excluded);
  if (defaultOptions.length) {
    options.forEach((optItem) => {
      optItem.isSelected = defaultOptions.indexOf(optItem.labelKey) !== -1;
    });
  }
  const selectEl = _react.default.useRef();
  const _useState = (0, _react.useState)(options);
  const _useState2 = _slicedToArray(_useState, 2);
  const optionsList = _useState2[0];
  const setOptionsList = _useState2[1];
  const _useState3 = (0, _react.useState)(false);
  const _useState4 = _slicedToArray(_useState3, 2);
  const menuVisible = _useState4[0];
  const setMenuVisible = _useState4[1];
  const _useState5 = (0, _react.useState)("hide");
  const _useState6 = _slicedToArray(_useState5, 2);
  const showDropdown = _useState6[0];
  const setShowDropdown = _useState6[1];
  const _useState7 = (0, _react.useState)("auto");
  const _useState8 = _slicedToArray(_useState7, 2);
  const menuHeight = _useState8[0];
  const setMenuHeight = _useState8[1];
  const _useState9 = (0, _react.useState)("");
  const _useState10 = _slicedToArray(_useState9, 2);
  const search = _useState10[0];
  const setSearch = _useState10[1];
  const _useState11 = (0, _react.useState)([]);
  const _useState12 = _slicedToArray(_useState11, 2);
  const beforeOpenVal = _useState12[0];
  const setBeforeOpenVal = _useState12[1];
  const btnPlaceHolder = placeholder && placeholder !== null ? placeholder : "No option selected";
  const prevOptions = (0, _react.useRef)();
  const prevDefaultOptions = (0, _react.useRef)();
  const initialState = {
    value: _toConsumableArray(options.filter((e) => e.isSelected).map((e) => e.value)),
    labelKey: _toConsumableArray(options.filter((e) => e.isSelected).map((e) => e.labelKey)),
  };
  const _useState13 = (0, _react.useState)(initialState.value.length > 0 ? initialState.value : []);
  const _useState14 = _slicedToArray(_useState13, 2);
  const selectedValue = _useState14[0];
  const setSelectedValue = _useState14[1];
  const _useState15 = (0, _react.useState)(
    initialState.labelKey.length > 0 ? initialState.labelKey : [],
  );
  const _useState16 = _slicedToArray(_useState15, 2);
  const selectedKey = _useState16[0];
  const setSelectedKey = _useState16[1];

  // Update options list on change option
  (0, _react.useEffect)(() => {
    if (
      JSON.stringify(prevOptions.current) !== JSON.stringify(options) ||
      JSON.stringify(prevDefaultOptions.current) !== JSON.stringify(defaultOptions)
    ) {
      if (defaultOptions.length) {
        options.forEach((optItem) => {
          optItem.isSelected = defaultOptions.indexOf(optItem.labelKey) !== -1;
        });
      }
      prevOptions.current = options;
      prevDefaultOptions.current = defaultOptions;
      setSelectedValue(_toConsumableArray(options.filter((e) => e.isSelected).map((e) => e.value)));
      setSelectedKey(
        _toConsumableArray(options.filter((e) => e.isSelected).map((e) => e.labelKey)),
      );
      setOptionsList(options);
    }
  }, [options, defaultOptions]);

  // Hide menu items
  const hideMenu = function hideMenu() {
    if (JSON.stringify(beforeOpenVal) !== JSON.stringify(selectedValue)) {
      options.length > 0 &&
        onClose &&
        onClose({
          el: selectEl.current,
          selectedValue,
          selectedKey,
        });
    }
    setMenuVisible(false);
    setShowDropdown("hide");
    setOptionsList(options);
    setSearch("");
  };

  // Execute callback function on change option
  (0, _react.useEffect)(() => {
    onChange &&
      onChange({
        el: selectEl.current,
        selectedValue,
        selectedKey,
      });
    !isMultiSelect && menuVisible && hideMenu();
  }, [selectedValue, selectedKey]);

  // Set dropdown menu height
  const setOptPosNHeight = function setOptPosNHeight() {
    if (optionsList.length) {
      let liListHeight = 0;
      let bsFunctionsHeight = 0;
      const currentEl = selectEl.current;
      const dropdown = currentEl.querySelector(".old-dropdown-menu:not(.old-inner)");
      const winInnerHeight = window.innerHeight;
      const dropDownInfo = currentEl.getBoundingClientRect();
      const dropDownBottom = winInnerHeight - dropDownInfo.bottom;
      const dropdownClone = dropdown.cloneNode(true);
      dropdownClone.style.visibility = "hidden";
      dropdownClone.classList.add("show");
      dropdownClone.classList.remove("hide");
      document.body.appendChild(dropdownClone);
      bsFunctionsHeight += showSearch
        ? dropdownClone.querySelector(".old-bs-searchbox").offsetHeight
        : 0;
      bsFunctionsHeight +=
        isMultiSelect && showAction
          ? dropdownClone.querySelector(".old-bs-actionsbox").offsetHeight
          : 0;
      const liArry = dropdownClone.querySelector(".old-dropdown-menu.old-inner").children;
      const liHeight = liArry[0].offsetHeight;
      const liTotalHeight = liArry.length * liHeight;
      if (menuSize) {
        if (dropDownInfo.top > 0 && dropDownBottom > 0) {
          if (typeof menuSize === "number") {
            liListHeight = `${liArry.length > menuSize ? liHeight * menuSize : liTotalHeight}px`;
          } else {
            const menuDrawPos =
              dropDownInfo.top > dropDownBottom
                ? dropDownInfo.top
                : winInnerHeight - dropDownInfo.bottom;
            const menuDrawSpace = menuDrawPos - bsFunctionsHeight - 23 - 2; // 23: Horizontal scroll bar height approx., 2: Fix decimal number variation

            liListHeight = menuDrawSpace < liTotalHeight ? menuDrawSpace : liTotalHeight;
          }
          setMenuHeight(liListHeight);
        }
      }
      dropDownInfo.top > dropDownBottom && liTotalHeight + bsFunctionsHeight > dropDownBottom
        ? currentEl.classList.add("dropup")
        : currentEl.classList.remove("dropup");
      document.body.removeChild(dropdownClone);
    }
  };

  // Handle event on button click to show/hide menu
  const handleBtnClick = function handleBtnClick(e) {
    setOptPosNHeight();
    setMenuVisible(!menuVisible);
    if (!menuVisible) {
      const selectedOptions = getSelectedVal();
      if (JSON.stringify(beforeOpenVal) !== JSON.stringify(selectedOptions.currentValue))
        setBeforeOpenVal(selectedOptions.currentValue);
    }
  };
  (0, _react.useEffect)(() => {
    setShowDropdown(menuVisible ? "show" : "hide");
  }, [menuVisible]);

  // Get all selected value
  var getSelectedVal = function getSelectedVal() {
    const index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
    const selectedOptions = {
      currentValue: [],
      currentKey: [],
    };
    optionsList.forEach((option, idx) => {
      if (index === idx) {
        if (selectedKey.indexOf(option.labelKey) === -1) {
          selectedOptions.currentValue !== undefined
            ? selectedOptions.currentValue.push(option.value)
            : (selectedOptions.currentValue = [option.value]);
          selectedOptions.currentKey !== undefined
            ? selectedOptions.currentKey.push(option.labelKey)
            : (selectedOptions.currentKey = [option.labelKey]);
        }
      } else if (selectedKey.indexOf(option.labelKey) !== -1) {
        selectedOptions.currentValue !== undefined
          ? selectedOptions.currentValue.push(option.value)
          : (selectedOptions.currentValue = [option.value]);
        selectedOptions.currentKey !== undefined
          ? selectedOptions.currentKey.push(option.labelKey)
          : (selectedOptions.currentKey = [option.labelKey]);
      }
    });
    return selectedOptions;
  };

  // Handle event on select on deselect option from dropdown
  const handleOptionClick = function handleOptionClick(option, index) {
    const currentKey = option.labelKey;
    const currentValue = option.value;
    if (isMultiSelect) {
      if (_typeof(selectedValue) === "object") {
        const selectedOptions = getSelectedVal(index);
        setSelectedValue(selectedOptions.currentValue);
        setSelectedKey(selectedOptions.currentKey);
      }
    } else {
      setSelectedValue([currentValue]);
      setSelectedKey([currentKey]);
    }
  };

  // Handle search
  const handleSearch = function handleSearch(e) {
    const searchTerm = e.target.value;
    let filteredOptions = [];
    filteredOptions = options.filter((opt) =>
      opt.value.toLowerCase().includes(e.target.value.trim().toLowerCase()),
    );
    setOptionsList(filteredOptions);
    setSearch(searchTerm);
  };

  // Handle select all & deselect all
  const handleSelectAll = function handleSelectAll() {
    const currentValue = [];
    const currentKey = [];
    optionsList.forEach((option) => {
      if (!option.isDisabled) {
        currentValue.push(option.value);
        currentKey.push(option.labelKey);
      }
    });
    setSelectedValue(currentValue);
    setSelectedKey(currentKey);
  };
  const handleDeselectAll = function handleDeselectAll() {
    setSelectedValue([]);
    setSelectedKey([]);
  };

  // Handle event on click outside of dropdown
  const handleClickOutside = (0, _react.useCallback)(
    (e) => {
      if (menuVisible && e.target.closest(".old-dropdown") !== selectEl.current) hideMenu();
    },
    [selectEl, menuVisible, showDropdown, options, search, selectedValue, selectedKey],
  );
  (0, _react.useEffect)(() => {
    document.addEventListener("click", handleClickOutside);
    return function () {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  // Handle window events
  (0, _react.useEffect)(() => {
    // Handle event on window resize
    const handleResize = function handleResize() {
      menuVisible && setOptPosNHeight();
    };
    window.addEventListener("resize", handleResize);

    // Handle event on window scroll
    const handleScroll = function handleScroll() {
      menuVisible && setOptPosNHeight();
    };
    window.addEventListener("scroll", handleScroll);
    return function () {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return /* #__PURE__ */ _react.default.createElement(
    "div",
    _extends({
      ref: selectEl,
      className: ""
        .concat(showTick ? "old-show-tick " : "")
        .concat(showSearch ? "old-show-search " : "")
        .concat(disabled ? "old-disabled " : "", "old-dropdown old-hk--custom--select ")
        .concat(className),
    }),
    /* #__PURE__ */ _react.default.createElement(
      "button",
      {
        type: "button",
        disabled,
        className: "old-btn old-dropdown-toggle "
          .concat(selectStyle, " ")
          .concat(disabled ? "old-disabled" : "", " ")
          .concat(selectedValue && selectedValue.length === 0 ? "old-bs-placeholder" : ""),
        "aria-expanded": menuVisible,
        title: selectedValue,
        onClick: function onClick(e) {
          return handleBtnClick(e);
        },
        ref,
        ...props,
      },
      /* #__PURE__ */ _react.default.createElement(
        "div",
        {
          className: "old-filter-option",
        },
        /* #__PURE__ */ _react.default.createElement(
          "div",
          {
            className: "old-filter-option-inner",
          },
          /* #__PURE__ */ _react.default.createElement(
            "div",
            {
              className: "old-filter-option-inner-inner",
            },
            isMultiSelect === true
              ? selectedValue && selectedValue.length > 0
                ? showCountOn && showCountOn < selectedValue.length
                  ? `${selectedValue.length} items selected`
                  : selectedValue.join(", ")
                : btnPlaceHolder
              : selectedValue && selectedValue.length > 0
                ? selectedValue
                : btnPlaceHolder,
          ),
        ),
      ),
    ),
    /* #__PURE__ */ _react.default.createElement(
      "div",
      {
        className:
          "old-dropdown-menu old-border-0 old-rounded-4 old-shadow old-overflow-hidden old-p-2 ".concat(
            showDropdown,
          ),
      },
      options.length > 0 &&
        showSearch &&
        /* #__PURE__ */ _react.default.createElement(
          "div",
          {
            className: "old-bs-box old-bs-searchbox old-old-border-bottom old-old-border-primary",
          },
          /* #__PURE__ */ _react.default.createElement("input", {
            className: "old-form-control",
            type: "search",
            autoComplete: "off",
            role: "combobox",
            "aria-label": "Search",
            placeholder: "Search",
            value: search,
            "aria-autocomplete": "list",
            onChange: function onChange(e) {
              return handleSearch(e);
            },
          }),
        ),
      isMultiSelect &&
        showAction &&
        optionsList.length > 0 &&
        /* #__PURE__ */ _react.default.createElement(
          "div",
          {
            className: "old-bs-box old-bs-actionsbox",
          },
          /* #__PURE__ */ _react.default.createElement(
            "div",
            {
              className: "old-btn-group old-btn-group-sm old-btn-block old-text-nowrap",
            },
            /* #__PURE__ */ _react.default.createElement(
              "button",
              {
                type: "button",
                disabled: optionsList.length === 0 && true,
                className: "old-btn old-actions-btn old-bs-select-all "
                  .concat(actionBtnStyle, " ")
                  .concat(optionsList.length === 0 ? "old-disabled" : ""),
                onClick: function onClick(e) {
                  return handleSelectAll(e);
                },
              },
              "Select All",
            ),
            /* #__PURE__ */ _react.default.createElement(
              "button",
              {
                type: "button",
                disabled: optionsList.length === 0 && true,
                className: "old-btn old-actions-btn old-bs-deselect-all "
                  .concat(actionBtnStyle, " ")
                  .concat(optionsList.length === 0 ? "old-disabled" : ""),
                onClick: function onClick(e) {
                  return handleDeselectAll(e);
                },
              },
              "Deselect All",
            ),
          ),
        ),
      /* #__PURE__ */ _react.default.createElement(
        "div",
        {
          className: "old-inner show",
          role: "listbox",
          style: {
            maxHeight: menuHeight,
            overflowY: "auto",
          },
        },
        /* #__PURE__ */ _react.default.createElement(
          "ul",
          {
            className: "old-dropdown-menu old-inner show",
            role: "presentation",
          },
          optionsList.length > 0
            ? optionsList.map((option, idx) => {
                const classList = option.isDisabled
                  ? " old-disabled"
                  : selectedValue.indexOf(option.value) !== -1
                    ? " old-selected active old-rounded-pill"
                    : "";
                const optClass = option.className ? `${option.className}` : "";
                return /* #__PURE__ */ _react.default.createElement(
                  "li",
                  {
                    key: "option_".concat(idx),
                    "data-value": option.value,
                    className: "".concat(optClass + classList),
                  },
                  /* #__PURE__ */ _react.default.createElement(
                    "button",
                    {
                      type: "button",
                      role: "option",
                      style: option.style,
                      onClick: function onClick() {
                        return handleOptionClick(option, idx);
                      },
                      className: "old-dropdown-item old-rounded-4".concat(classList),
                    },
                    option.icon &&
                      /* #__PURE__ */ _react.default.createElement("img", {
                        src: option.icon,
                        className: "!size-5 old-me-2",
                      }),
                    showTick &&
                      selectedValue.indexOf(option.value) !== -1 &&
                      /* #__PURE__ */ _react.default.createElement("span", {
                        className: "old-hkicon-checkmark old-check-mark",
                      }),
                    /* #__PURE__ */ _react.default.createElement(
                      "span",
                      {
                        className: "old-text",
                      },
                      option.value,
                    ),
                  ),
                );
              })
            : /* #__PURE__ */ _react.default.createElement(
                "li",
                null,
                /* #__PURE__ */ _react.default.createElement(
                  "div",
                  {
                    className: "old-dropdown-item old-disabled",
                  },
                  options.length > 0 && showSearch && selectEl.current !== undefined
                    ? /* #__PURE__ */ _react.default.createElement(
                        _react.default.Fragment,
                        null,
                        "No result match ",
                        /* #__PURE__ */ _react.default.createElement(
                          "span",
                          {
                            className: "old-font-italic old-font-weight-bold",
                          },
                          "'",
                          selectEl.current.querySelector('[type = "search"]').value,
                          "'",
                        ),
                      )
                    : "No option to select",
                ),
              ),
        ),
      ),
    ),
  );
});
export default BootstrapSelect;
