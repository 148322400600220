import { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";

import { useSubmission } from "features/submission";
import { UserContext } from "features/user";

import FeedbackList from "./FeedbackList";
import FeedbackInput from "./FeedbackInput";
import EngagementCTA from "./EngagementCTA";

export default function FeedbackContainer({ submissionId }) {
  const [parent, setParent] = useState(null);
  const inputRef = useRef(null);

  const { data: submission } = useSubmission(submissionId);
  const { user } = useContext(UserContext);

  const handleReply = (comment) => {
    setParent(comment);
    if (inputRef.current) inputRef.current.focus();
  };

  return (
    <div className="bg-white rounded-3xl flex flex-col max-h-[85vh] min-h-[300px]">
      <div className="flex-1 overflow-y-auto px-6 pt-4">
        {user.doesSessionExist && (
          <FeedbackList submissionId={submissionId} handleReply={handleReply} />
        )}
        <EngagementCTA designer={submission.user} user={user} />
      </div>
      <div className="px-6 pb-6 border-t-6 border-gray-200 relative">
        <div className="absolute inset-x-0 top-[-38px] h-10 bg-gradient-to-t from-white via-white to-transparent pointer-events-none w-full" />
        <FeedbackInput
          ref={inputRef}
          submission={submission}
          parent={parent}
          onCancelReply={() => setParent(null)}
        />
      </div>
    </div>
  );
}
FeedbackContainer.propTypes = {
  submissionId: PropTypes.number.isRequired,
};
