import HighlightIcon from "@mui/icons-material/Highlight";
import { Row, Col, Container, Shimmer } from "ui";
import SpotlightSubmission from "default/pages/Spotlight/SpotlightSubmission";
import { PageLayout } from "features/navigation";
import { useSpotlight } from "features/spotlight";
import prospectIcon from "images/icons/prospect100icon-inverted.svg";

function SpotlightLoading() {
  return (
    <Container className="old-full-size old-w-100 old-sb-6 old-py-6">
      <Row className="old-h-100" gap={6}>
        <Col xs={12} lg={4} className="old-d-flex old-flex-column old-justify-content-center">
          <div className="old-mb-3 old-mb-md-6">
            <Shimmer className="old-ratio old-ratio-1x1 !size-12 md:!size-16 old-rounded-circle old-mb-3" />
            <Shimmer className="old-w-75 old-w-lg-100 old-p-2 old-p-md-3 old-rounded-pill old-mb-3" />
            <Shimmer className="old-w-50 old-p-2 old-p-lg-3 old-rounded-pill" />
          </div>

          <div className="old-mb-3 old-mb-md-6">
            <Shimmer className="old-ratio old-ratio-1x1 !size-12 md:!size-16 old-rounded-circle old-mb-3" />
            <Shimmer className="old-w-75 old-w-lg-100 old-p-2 old-p-md-3 old-rounded-pill old-mb-3" />
            <Shimmer className="old-w-50 old-p-1 old-p-md-3 old-rounded-pill" />
          </div>
        </Col>

        <Col
          xs={12}
          lg={6}
          className="old-d-flex old-flex-column old-justify-content-end old-justify-content-lg-center old-mb-3 old-mb-md-6"
        >
          <Shimmer className="old-w-75 old-p-2 old-rounded-pill old-mb-3" />
          <Shimmer className="old-w-75 old-w-lg-100 old-p-3 old-rounded-pill old-mb-5" />
          <Shimmer className="old-w-50 old-w-lg-75 old-p-3 old-p-md-6 old-rounded-pill" />
        </Col>

        <Col
          xs={2}
          className="old-position-absolute old-end-0 old-pe-md-gutter old-me-3 old-me-md-0 old-d-flex old-flex-column old-h-75 old-mt-3 old-mt-md-6 old-justify-content-center"
        >
          <div className="old-align-self-end">
            <Shimmer className="!size-12 md:!size-16 old-rounded-circle" />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default function Spotlight() {
  const { data: spotlightedSubmissions, isLoading } = useSpotlight();

  return (
    <PageLayout
      pageName="Spotlight"
      meta={{
        description: "Oditi Spotlighted Submissions",
      }}
      showFooter={false}
      className="text-primary-foreground"
      navbarProps={{
        size: "none",
        icon: prospectIcon,
      }}
    >
      {isLoading && <SpotlightLoading />}

      {!isLoading && !spotlightedSubmissions?.length && (
        <Container className="old-full-size old-w-100 old-d-flex old-flex-column old-align-items-center old-justify-content-center old-all-mb-6">
          <HighlightIcon className="!size-24 old-rotate-180" />
          <h3 className="text-center">
            There is currently no spotlight to display,
            <br />
            please check again later!
          </h3>
        </Container>
      )}

      {!isLoading && spotlightedSubmissions?.length && (
        <div className="snap-y overflow-y-scroll snap-mandatory h-screen w-screen">
          {spotlightedSubmissions.map((submission, index) => (
            <div key={submission.id} className="snap-start">
              <SpotlightSubmission
                spotlight={submission}
                number={spotlightedSubmissions.length - index}
              />
            </div>
          ))}
        </div>
      )}
    </PageLayout>
  );
}
