import { useNavigate, generatePath } from "react-router-dom";
import { useMemo } from "react";
import { ImageCard, ModalClickContainer, Avatar, Chip } from "ui";
import { briefListPropTypes } from "features/brief/schemas/briefSchema";
import { briefStatusContent } from "features/brief/data/briefData";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import BriefNotInterestedModal from "features/brief/components/modal/BriefNotInterestedModal";
import CloseIcon from "@mui/icons-material/Close";
import Mixpanel from "adapters/mixpanel";
import routes from "default/routes";

function BriefStatusBadge({ brief }) {
  const countdown = useMemo(() => {
    const { reminder } = briefStatusContent(brief)[brief.status];
    return reminder;
  }, [brief]);

  if (brief.status === "completed") {
    return (
      <Chip
        className={twMerge(
          "bg-content1 uppercase",
          brief.submitted && "bg-gold text-primary-foreground old-shine-horizontal",
        )}
        radius="sm"
      >
        {brief.submitted ? "Completed" : "Submit your design!"}
      </Chip>
    );
  }
  if (countdown) {
    return (
      <Chip radius="sm" color="danger" className="uppercase old-shake">
        {countdown}
      </Chip>
    );
  }
  if (brief.status !== "scheduled" || brief.status !== "completed") {
    return (
      <Chip color="danger" radius="sm" className="uppercase old-pulse-shadow">
        Live
      </Chip>
    );
  }
}
BriefStatusBadge.propTypes = {
  brief: briefListPropTypes.isRequired,
};

function Overlay({ brief = {} }) {
  return (
    <div className="old-hover-overlay old-bg-dark old-bg-opacity-25 old-w-100 old-h-100">
      {brief.user_submission_count <= 0 && (
        <div className="old-overlay-fade-top old-position-absolute old-h-100 old-top-0 old-w-100 old-pe-3 old-pt-3 old-d-none old-d-lg-flex old-justify-content-end">
          <div role="button" tabIndex={0} onClick={(e) => e.stopPropagation()} className="old-z-1">
            <ModalClickContainer
              modal={BriefNotInterestedModal}
              modalProps={{
                brief,
              }}
            >
              <CloseIcon className="!size-9 bg-content2 text-foreground p-2 rounded-full" />
            </ModalClickContainer>
          </div>
        </div>
      )}

      <div className="absolute w-full h-full top-0 pt-4 px-4">
        <div className="flex justify-between items-top">
          <Avatar src={brief.organisation.icon} name={brief.organisation.name} />
          {brief.user_submission_count > 0 ? (
            <Chip radius="sm" color="primary" className="bg-opacity-75 uppercase">
              {`${brief.user_submission_count} ${brief.user_submission_count === 1 ? "Submission" : "Submissions"}`}
            </Chip>
          ) : (
            <ModalClickContainer
              modal={BriefNotInterestedModal}
              modalProps={{
                brief,
              }}
            >
              <CloseIcon className="!size-9 bg-content2 text-foreground p-2 rounded-full lg:!hidden" />
            </ModalClickContainer>
          )}
        </div>
      </div>

      <div className="absolute bottom-0 flex flex-wrap items-center justify-end w-full px-4 gap-2 pb-4">
        <BriefStatusBadge brief={brief} />
      </div>
    </div>
  );
}
Overlay.propTypes = {
  brief: briefListPropTypes,
};

export default function SmallBriefCard({
  entry: brief = {},
  onClick = undefined,
  blank = false,
  trackingName = "view brief",
  trackingLocation = "",
  ...optionsProps
}) {
  const navigate = useNavigate();
  const navigateOnClick = () => {
    if (onClick) {
      onClick(brief);
      return;
    }
    navigate(generatePath(routes.brief, { id: brief.id }));
  };

  const handleTrackOnClick = () => {
    Mixpanel.trackButton("Card", trackingName, trackingLocation);
    navigateOnClick();
  };

  return (
    <ImageCard
      name="brief"
      className="w-64 min-w-56"
      blank={!brief || blank}
      image={brief?.thumbnail || ""}
      onClick={handleTrackOnClick}
      overlay={Overlay}
      overlayProps={{
        brief,
        ...optionsProps,
      }}
    />
  );
}
SmallBriefCard.propTypes = {
  entry: briefListPropTypes,
  onClick: PropTypes.func,
  blank: PropTypes.bool,
  trackingName: PropTypes.string,
  trackingLocation: PropTypes.string,
};
