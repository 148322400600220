import { forwardRef } from "react";
import { Button as NextButton } from "@nextui-org/react";
import { twMerge } from "tailwind-merge";
import Mixpanel from "adapters/mixpanel";
import PropTypes from "prop-types";

const Button = forwardRef(
  ({ onClick, radius, trackingLocation, trackingName, className, children, ...props }, ref) => {
    const handleTrackOnClick = (e) => {
      Mixpanel.trackButton("Button", trackingName, trackingLocation);
      if (onClick) onClick(e);
    };

    return (
      <NextButton
        onClick={handleTrackOnClick}
        radius={radius}
        className={twMerge("uppercase", className)}
        ref={ref}
        {...props}
      >
        {children}
      </NextButton>
    );
  },
);
Button.propTypes = {
  onClick: PropTypes.func,
  radius: PropTypes.string,
  trackingLocation: PropTypes.string,
  trackingName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
Button.defaultProps = {
  onClick: undefined,
  radius: "full",
  trackingLocation: "",
  trackingName: "",
  className: "",
};

export default Button;
