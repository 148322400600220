const checkFontSupport = () => /iPad|iPhone|iPod/.test(navigator.userAgent);

export default function configureOSFont() {
  if (checkFontSupport()) {
    const newStyle = document.createElement("style");
    newStyle.appendChild(
      document.createTextNode(
        "html, body {font-family: 'Helvetica-Neue-LT-Pro-Bold-Condensed-IOS', sans-serif;}",
      ),
    );
    document.head.appendChild(newStyle);
  }
}
