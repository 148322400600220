import { useContext } from "react";
import { useUpdateUser, UserContext } from "features/user";
import { Form, Button } from "ui";
import { userOnboardingAvatarSchema } from "utils/validation/validationSchemas";
import PropTypes from "prop-types";
import FormFilePreviewAvatar from "forms/file/FormFilePreviewAvatar";
import ValidateForm from "forms/wrappers/ValidationWrappers";

export default function OnboardingAvatar({ onComplete }) {
  const { user } = useContext(UserContext);
  const { mutateAsync } = useUpdateUser();

  const handleUpdateAvatar = async (formData) => {
    // If they already set the icon, we skip
    if (formData.icon?.size <= 0) {
      onComplete();
      return {};
    }

    delete formData.iconUrl;

    try {
      await mutateAsync(formData);
    } catch (error) {
      return {
        errors: {
          ...error.parseError(formData),
          api: error.details,
        },
      };
    }

    onComplete();
    return {};
  };

  const handleParseAvatar = async (formData) => {
    if (user.icon || formData.icon_onboarding.size > 0)
      formData.iconUrl = URL.createObjectURL(formData.icon_onboarding);
    formData.icon = formData.icon_onboarding;
    delete formData.icon_onboarding;
    return formData;
  };

  return (
    <div data-testid="user-onboarding-avatar">
      <h2 className="capitalize old-mb-5 old-fs-1">Add an Avatar</h2>

      <ValidateForm
        onSubmit={handleUpdateAvatar}
        parseData={handleParseAvatar}
        validationSchema={userOnboardingAvatarSchema}
        scrollOnSubmit={false}
        errorPage="onboarding"
      >
        {({ handleSubmit, errors, loading }) => (
          <Form onSubmit={handleSubmit} validated={false} noValidate autoComplete="off">
            <div className="mb-8">
              <FormFilePreviewAvatar
                name="icon_onboarding"
                defaultValue={user.icon}
                error={errors.icon || errors.iconUrl}
                avatarProps={{
                  name: user.username,
                }}
              />
            </div>

            <Button
              type="submit"
              color="primary"
              isLoading={loading}
              trackingName="continue onboarding"
              fullWidth
            >
              Continue
            </Button>
          </Form>
        )}
      </ValidateForm>
    </div>
  );
}
OnboardingAvatar.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
