import { pulse, flipInX, fadeInUp, fadeIn, headShake, slideInUp } from "react-animations";
import { StyleSheet, css } from "aphrodite";

// Duration always goes first
// then animation name
const animationStyles = StyleSheet.create({
  pulse: {
    animationDuration: ".5s",
    animationName: pulse,
  },
  flipIn: {
    animationDuration: "1s",
    animationName: flipInX,
  },
  fadeInUp: {
    animationDuration: ".8s",
    animationName: fadeInUp,
  },
  fadeIn: {
    animationDuration: "1s",
    animationName: fadeIn,
  },
  bounceIn: {
    animationDuration: "1s",
    animationName: headShake,
  },
  bounceInUp: {
    animationDirection: ".3s",
    animationName: slideInUp,
  },
});

// Allows us to override the default animation duration, and add delays before the animation starts
export const createAnimation = ({ name, duration = 1, delay = 0 }) => {
  const timings = StyleSheet.create({
    delay: {
      animationDuration: `${duration}s`,
      animationDelay: `${delay}s`,
    },
  });
  return css(animationStyles[name], timings.delay);
};

export default animationStyles;
