import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ScrollToTop from "react-scroll-to-top";
import PropTypes from "prop-types";

export default function ScrollToTopButton({ top = 1000 }) {
  return (
    <ScrollToTop
      smooth
      top={top}
      component={
        <ArrowUpwardIcon className="old-bg-primary old-text-secondary old-rounded-2 !size-10 old-shadow old-p-2" />
      }
      className="old-border-0 old-rounded-2 old-d-flex old-align-items-center old-justify-content-center old-bottom-6 old-mb-6"
    />
  );
}

ScrollToTopButton.propTypes = {
  top: PropTypes.number,
};
