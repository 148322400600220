import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const alert = tv({
  slots: {
    base: "bg-purple-100 text-purple rounded-3xl text-center p-4 mb-4",
    close: "text-primary-300 absolute right-0 mr-4",
  },
});

export default function Alert({
  isDismissible = false,
  className = "",
  classNames = {},
  children,
  ...props
}) {
  const [show, setShow] = useState(true);
  const { base, close } = alert();

  return (
    <div
      role="alert"
      className={twMerge(
        base(),
        !show && "hidden",
        isDismissible && "pr-12",
        className,
        classNames.base,
      )}
      data-slot="base"
      {...props}
    >
      {isDismissible && (
        <CloseIcon
          className={twMerge(close(), className, classNames.close)}
          role="button"
          onClick={() => setShow(false)}
          data-slot="close"
        />
      )}

      {children}
    </div>
  );
}
Alert.propTypes = {
  isDismissible: PropTypes.bool,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    base: PropTypes.string,
    close: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};
