import ForumIcon from "@mui/icons-material/Forum";
import { userPropTypes } from "features/user";

export default function EngagementCTA({ designer, user }) {
  const message = user.doesSessionExist
    ? `What did you think of ${designer.username}'s design?`
    : `Log in to see feedback for ${designer.username}'s design`;

  return (
    <div className="bg-default-100 rounded-lg p-6 mb-4 text-center w-full">
      <ForumIcon className="mx-auto mb-3 text-primary-200 w-12 h-12" />
      <h3 className="text-sm sm:text-xl uppercase font-semibold mb-2 text-primary-200">
        {message}
      </h3>
      <p className="text-sm sm:text-md text-gray-600 mb-1">
        Your insights can inspire and elevate fellow designers. Join the conversation and make a
        difference!
      </p>
    </div>
  );
}
EngagementCTA.propTypes = {
  user: userPropTypes.isRequired,
  designer: userPropTypes.isRequired,
};
