import { extendVariants, ListboxItem } from "@nextui-org/react";

const CustomListboxItem = extendVariants(ListboxItem, {
  variants: {
    color: {
      default: {
        base: "data-[hover=true]:bg-default-100",
      },
    },
  },
});

export default CustomListboxItem;
