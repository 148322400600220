import Col from "ui/layout/Col";
import PropTypes from "prop-types";

export default function SimpleCardBody({ title = "", description = "", blank = false }) {
  if (blank) return null;

  return (
    <Col
      className="old-all-mb-3 old-d-flex old-flex-column old-mt-3"
      data-testid="image-card-simple-body"
    >
      <Col>
        <h3 className="capitalize old-mb-0 old-description-2 text-primary">{title}</h3>
      </Col>
      <p className="old-description-2 old-fs-20 old-flex-grow-1 text-primary-200">{description}</p>
    </Col>
  );
}

SimpleCardBody.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  blank: PropTypes.bool,
};
