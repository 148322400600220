import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
} from "@nextui-org/react";
import { twMerge } from "tailwind-merge";
import styled from "styled-components";

const StyledDropdownMenu = styled(DropdownMenu).attrs((props) => ({
  classNames: {
    list: twMerge("m-0 p-0 list-none", props.classNames?.list || ""),
  },
  itemClasses: {
    base: twMerge("rounded-full px-4", props.itemClasses?.base || ""),
  },
  variant: props?.variant || "flat",
}))``;

Dropdown.DropdownTrigger = DropdownTrigger;
Dropdown.DropdownMenu = StyledDropdownMenu;
Dropdown.DropdownItem = DropdownItem;
Dropdown.DropdownSection = DropdownSection;
export default Dropdown;
