import { useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "features/authentication";
import { UserContext } from "features/user";
import { ProgressStep, Button, Section } from "ui";
import routes from "default/routes";

export default function CompleteOnboarding() {
  const { showAuth } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const hasSocials = Object.keys(user.metadata?.socials || {}).length > 0;
  const completedAll = (user.metadata?.completed_onboarding || false) && user.icon && hasSocials;

  const currentStep = useMemo(() => {
    if (!(user.metadata?.completed_onboarding || false)) {
      return {
        step: 1,
        ctaText: "Complete Onboarding",
        ctaAction: () => showAuth({ onboarding: true }),
      };
    }
    if (!user.icon) {
      return {
        step: 2,
        ctaText: "Add Avatar",
        ctaAction: () => navigate(routes.userProfileEdit),
      };
    }
    if (!hasSocials) {
      return {
        step: 3,
        ctaText: "Add Socials",
        ctaAction: () => navigate(routes.userProfileEdit),
      };
    }
    return {
      step: 4,
      ctaText: "done!",
    };
  }, [user]);

  if (completedAll) return null;

  return (
    <Section
      className="bg-content1 rounded-3xl overflow-hidden text-center md:-mx-8 mb-4 shadow py-6 text-foreground w-auto"
      data-testid="complete-onboarding"
    >
      <h4>You&#39;re Almost Done!</h4>

      <div className="flex w-full m-auto">
        <ProgressStep
          position="first"
          title="Create An Account"
          step={1}
          currentStep={currentStep.step}
          classNames={{
            base: currentStep.step > 2 && "hidden",
            title: "!text-sm px-2",
          }}
        />
        <ProgressStep
          title="Add an Avatar"
          step={2}
          currentStep={currentStep.step}
          classNames={{
            base: currentStep.step > 3 && "hidden",
            title: "!text-sm px-2",
          }}
        />
        <ProgressStep
          title="Add Socials"
          step={3}
          currentStep={currentStep.step}
          classNames={{
            base: currentStep.step < 3 && "hidden",
            title: "!text-sm px-2",
          }}
        />
        <ProgressStep
          position="last"
          title="Done!"
          step={4}
          currentStep={currentStep.step}
          classNames={{
            base: currentStep.step < 4 && "hidden",
            title: "!text-sm px-2",
          }}
        />
      </div>

      <Button
        color="primary"
        trackingName="view complete onboarding"
        onClick={currentStep.ctaAction}
      >
        {currentStep.ctaText}
      </Button>
    </Section>
  );
}
