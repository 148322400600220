import { useState, useRef } from "react";
import { Avatar, CropModal } from "ui";
import { objectUrlToFile, writeFilesToInput } from "utils/helpers";
import { useDisclosure } from "@nextui-org/react";
import FormFile from "forms/file/FormFile";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PropTypes from "prop-types";

function PreviewIcon({ previewSrc, changeText = "Change Avatar", name = "" }) {
  return (
    <div className="old-mx-auto text-center">
      <div className="old-hover-overlay old-position-relative old-d-inline-block old-mb-3">
        <Avatar src={previewSrc} name={name} className="!size-52" />

        <div className="old-overlay-fade-top old-position-absolute old-top-0 old-start-0 old-w-100 old-h-100 old-rounded-circle old-text-white old-d-flex old-align-items-center old-justify-content-center old-flex-column">
          <FileUploadOutlinedIcon className="!size-10" />
          <span>Upload</span>
        </div>
      </div>

      <div className="old-d-flex old-align-items-center old-justify-content-center old-text-lightgrey">
        <FileUploadOutlinedIcon className="old-me-1" />
        <span>{changeText}</span>
      </div>
    </div>
  );
}
PreviewIcon.propTypes = {
  previewSrc: PropTypes.string.isRequired,
  changeText: PropTypes.string,
  name: PropTypes.string,
};

export default function FormFilePreviewAvatar({
  defaultValue = null,
  onUpdate = undefined,
  avatarProps = {},
  cropProps = {},
  ...fileProps
}) {
  const [previewSrc, setPreviewSrc] = useState(defaultValue);
  const [src, setSrc] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fileRef = useRef(null);

  const handleSuccess = async (croppedSrc) => {
    setPreviewSrc(croppedSrc);
    const file = await objectUrlToFile(croppedSrc, fileRef.current.files[0].name);
    writeFilesToInput([file], fileRef.current);

    if (onUpdate) onUpdate(croppedSrc, file);
  };

  return (
    <>
      <FormFile
        onUpdate={(file) => {
          setSrc(URL.createObjectURL(file));
          onOpen();
        }}
        as={PreviewIcon}
        fileProps={{
          previewSrc,
          ...avatarProps,
        }}
        ref={fileRef}
        {...fileProps}
      />

      <CropModal
        onSuccess={handleSuccess}
        show={isOpen}
        onHide={onClose}
        src={src}
        maxWidth={512}
        maxHeight={512}
        aspect={1 / 1}
        cropShape="round"
        {...cropProps}
      />
    </>
  );
}
FormFilePreviewAvatar.propTypes = {
  defaultValue: PropTypes.string,
  onUpdate: PropTypes.func,
  avatarProps: PropTypes.shape({}),
  cropProps: PropTypes.shape({}),
};
