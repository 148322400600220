import { Link } from "react-router-dom";
import User from "ui/content/User";
import Chip from "ui/status/Chip";
import PropTypes from "prop-types";

export default function CardBody({
  userIcon = "",
  username = "",
  userPath = "",
  title = "",
  subTitle = "",
  // tags = [],
  status = "",
  isCompleted = false,
}) {
  return (
    <div
      className="old-p-3 old-pb-0 old-all-mb-3 old-all-mb-md-5 old-flex-grow-1 old-d-flex old-flex-column"
      data-testid="image-card-body"
    >
      <div className="old-d-flex old-justify-content-between old-align-items-center">
        <User
          name={username}
          avatarProps={{
            src: userIcon,
            size: "sm",
            isBordered: true,
          }}
          classNames={{
            base: "bg-transparent gap-3 px-0",
            name: "normal-case hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-left before:transition-transform before:duration-500 before:scale-x-0 before:bg-current before:absolute before:left-0 before:bottom-0",
          }}
          as={Link}
          to={userPath}
          data-testid="image-card-profile"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />

        {["submitting", "voting", "judging"].includes(status) && (
          <Chip radius="sm" size="lg" color="danger" className="uppercase old-pulse-shadow">
            Live
          </Chip>
        )}

        {isCompleted && (
          <Chip
            radius="sm"
            size="lg"
            className="uppercase bg-gold text-primary-foreground old-shine-horizontal"
          >
            Completed
          </Chip>
        )}
      </div>

      {subTitle && (
        <small className="old-description-1 font-bold old-fs-12 old-mb-3">{subTitle}</small>
      )}

      <p className="font-bold old-py-md-0 old-py-1 old-fs-5 old-mb-0 old-description-2 old-flex-grow-1 old-mb-3 old-mb-md-5">
        {title}
      </p>
    </div>
  );
}
CardBody.propTypes = {
  userIcon: PropTypes.string,
  username: PropTypes.string,
  userPath: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  status: PropTypes.string,
  isCompleted: PropTypes.bool,
};
