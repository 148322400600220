import { useMemo, useContext } from "react";
import { generatePath } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import { briefListPropTypes } from "features/brief/schemas/briefSchema";
import { NavigationConfigContext } from "features/navigation";
import { briefStatusContent } from "features/brief/data/briefData";
import { IconButton, ImageCard, Chip } from "ui";
import routes from "default/routes";
import PropTypes from "prop-types";

function Overlay({ countdown = "" }) {
  return (
    <div className="old-rounded-4">
      <div className="old-position-absolute old-bottom-0 old-d-flex old-justify-content-end old-align-items-center old-w-100 old-px-3 old-mb-3">
        {countdown?.length > 0 && (
          <Chip color="danger" radius="sm" className="uppercase old-shake" size="lg">
            {countdown}
          </Chip>
        )}
        <div className="!size-8 sm:!size-10 old-bg-transparent old-ms-3">
          <IconButton.Arrow className="old-bg-white old-rounded-circle old-p-1 old-shadow old-text-dark" />
        </div>
      </div>
    </div>
  );
}
Overlay.propTypes = {
  countdown: PropTypes.string,
};

export default function BriefCard({ entry: brief = {}, blank = false, ...optionsProps }) {
  const { config } = useContext(NavigationConfigContext);
  const navigate = useNavigate();

  // If there are no briefs we return a blank image card
  if (!brief || blank) return <ImageCard blank header={ImageCard.Body} />;

  const countdown = useMemo(() => {
    const { reminder } = briefStatusContent(brief)[brief.status];
    return reminder;
  }, [brief]);

  // const briefTags = useMemo(() => {
  //     const isLive = ['proposing', 'selecting', 'submitting', 'voting', 'judging'].includes(brief.status);
  //     const entryReminder = calculateReminderTime(brief.submission_deadline);
  //     const votingReminder = calculateReminderTime(brief.voting_deadline);
  //     const now = new Date();
  //     return [
  //         ...(brief.status === 'scheduled' ? [{ text: 'Upcoming', bg: 'success' }] : []),
  //         ...(isLive ? [{ text: 'LIVE', bg: 'danger', }] : []),
  //         ...(brief?.design_interests ? brief.design_interests.map((d) => ({ text: d, bg: 'purple' })) : []),
  //         ...((now >= entryReminder && now < dayjs(brief.submission_deadline) && brief.status === 'submitting') ? [{ text: 'Entry Closing Soon', bg: 'gold' }] : []),
  //         ...((now >= votingReminder && now < dayjs(brief.voting_deadline) && brief.status === 'voting') ? [{ text: 'Voting Closing Soon', bg: 'gold' }] : []),
  //     ];
  // }, [brief.design_interests, brief.status]);

  return (
    <ImageCard
      name="brief"
      image={brief?.thumbnail || ""}
      onClick={() => navigate(generatePath(routes.brief, { id: brief.id }))}
      shareUrl={generatePath(routes.brief, { id: brief.id })}
      header={ImageCard.Body}
      userPath={generatePath(config.redirectDefault, {
        wildcard: generatePath(routes.organisation, {
          id: brief.organisation.id,
        }),
      })}
      userIcon={brief.organisation.icon}
      username={brief.organisation.name}
      // tags={briefTags}
      status={brief.status}
      isCompleted={brief.status === "completed" && brief.submitted}
      title={brief.title}
      overlay={Overlay}
      overlayProps={{
        brief,
        countdown,
        ...optionsProps,
      }}
    />
  );
}
BriefCard.propTypes = {
  entry: briefListPropTypes,
  blank: PropTypes.bool,
};
