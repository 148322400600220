import { useState, useMemo, useContext } from "react";
import { UserContext, AddUserInfoModal } from "features/user";
import { FeedbackPromptModal, useFeedback } from "features/feedback";
import { useProtectedAction } from "features/authentication";
import { abbreviateNumber } from "utils/helpers";
import { Button } from "ui";
import PropTypes from "prop-types";
import useAnimateVoteButton from "hooks/useAnimateVoteButton";
import VoteIcon from "icons/VoteIcon";
import Mixpanel from "adapters/mixpanel";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import useSubmissionWithActions from "features/submission/hooks/useSubmissionWithActions";

export default function SubmissionVoteButton({ trackingLocation, submissionId }) {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { data: feedback } = useFeedback(submissionId);
  const { submission, handleVote, isLoading } = useSubmissionWithActions(submissionId);
  const showVotes = submission?.voted && !loading;
  const hasGivenFeedback = useMemo(
    () => feedback?.comments?.some((f) => f.user.id === user.id),
    [feedback],
  );
  const { animateVerified, animateVotes } = useAnimateVoteButton(showVotes);
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const FEEDBACK_PROMPT_PROBABILITY = 0.4;
  const handleDoVote = async ({ skipAddInfo = false, skipFeedback = false } = {}) => {
    // if missing user data, show verify modal
    if (!skipAddInfo && !(user.dob && user.country_code && user.gender)) setShowUserInfoModal(true);
    // trigger vote first, regardless of the feedback modal
    await handleVote();
    setLoading(false);
    // show feedback modal if needed, but after voting is handled
    if (!skipFeedback && !hasGivenFeedback && Math.random() < FEEDBACK_PROMPT_PROBABILITY)
      setShowFeedbackModal(true);
  };

  const [handleOnClick] = useProtectedAction(async () => {
    if (showVotes) return;
    Mixpanel.trackButton("Action Button", "vote", trackingLocation);
    setLoading(true);
    handleDoVote();
  });

  return (
    <>
      <AddUserInfoModal
        show={showUserInfoModal}
        onHide={() => {
          setLoading(false);
          setShowUserInfoModal(false);
        }}
        onSuccess={() => {
          // We skip both verify and feedback to prevent modal popup after modal popup hell (bad for user)
          handleDoVote({ skipAddInfo: true, skipFeedback: true });
          setShowUserInfoModal(false);
        }}
        title="Let's verify your vote!"
        footerProps={{
          confirmText: "Confirm Vote",
        }}
      />

      <FeedbackPromptModal
        show={showFeedbackModal}
        onSuccess={() => {
          setShowFeedbackModal(false);
          handleDoVote({ skipAddInfo: true, skipFeedback: true });
        }}
        submissionId={submissionId}
      />

      <Button
        color="success"
        size="lg"
        startContent={showVotes ? <VerifiedOutlinedIcon ref={animateVerified} /> : <VoteIcon />}
        className="sm:px-8 mr-2 gap-2 uppercase"
        onClick={handleOnClick}
        isLoading={loading || isLoading}
        data-testid="vote-button"
        spinnerPlacement="end"
      >
        {showVotes ? (
          <span ref={animateVotes}>{abbreviateNumber(submission?.votes, 1)}</span>
        ) : (
          "Vote"
        )}
      </Button>
    </>
  );
}

SubmissionVoteButton.propTypes = {
  trackingLocation: PropTypes.string.isRequired,
  submissionId: PropTypes.number.isRequired,
};
