import { useCallback } from "react";
import { css } from "aphrodite";
import Container from "ui/layout/Container";
import animationStyles from "ui/animations";
import PropTypes from "prop-types";
import useKeyGen from "hooks/useKeyGen";

export default function TitleBanner({
  titleComponents = [],
  subText = "",
  titleClassName = "",
  backgroundImage = "",
  backgroundVideo = "",
}) {
  const keyGen = useKeyGen();

  const playVideo = useCallback((node) => {
    if (!node) return;

    node.setAttribute("autoplay", "autoplay");
  }, []);

  return (
    <div className="old-py-6 text-center old-d-flex old-flex-column old-align-items-center old-justify-content-center old-vh-100">
      {backgroundImage && (
        <img
          className="old-position-absolute old-w-100 old-h-100 old-object-fit-cover"
          src={backgroundImage}
          alt="banner background"
        />
      )}
      {backgroundVideo && (
        <video
          playsInline
          preload="auto"
          muted
          loop
          className="old-w-100 old-h-100 old-object-fit-cover old-position-absolute"
          ref={playVideo}
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      )}

      <Container className="old-all-mb-banner-text">
        <h1 className={`old-mb-0 uppercase old-d-inline-block ${titleClassName}`}>
          <span>
            {titleComponents.map((title) => (
              <div key={keyGen.getKey(title)}>
                <div
                  className={`old-d-inline-block old-w-100 old-transition-underline-center-load old-text-nowrap ${css(animationStyles.fadeInUp)}`}
                >
                  {title}
                </div>
                <br />
              </div>
            ))}
          </span>
        </h1>

        {subText !== "" && (
          <p className="old-fs-20 old-mw-lg-75 old-mw-xl-50 old-m-auto">{subText}</p>
        )}
      </Container>
    </div>
  );
}
TitleBanner.propTypes = {
  titleComponents: PropTypes.arrayOf(PropTypes.string),
  subText: PropTypes.node,
  titleClassName: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundVideo: PropTypes.string,
};
