import { useState } from "react";
import { getCroppedImg } from "ui/modals/CropModal/cropUtils";
import { toast } from "react-toastify";
import Cropper from "react-easy-crop";
import Modal from "ui/modals/Modal";
import Slider from "ui/status/Slider";
import PropTypes from "prop-types";

export default function CropModal({
  onHide,
  onSuccess,
  src,
  aspect = 4 / 3,
  show = false,
  maxWidth = 800,
  maxHeight = 600,
  ...cropProps
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleClose = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    onHide();
  };

  const handleCropImage = async () => {
    try {
      const croppedImage = await getCroppedImg(src, croppedAreaPixels, maxWidth, maxHeight, 0);

      onSuccess(croppedImage);
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error(<div data-testid="error-toast">Unable to crop image, please try again.</div>, {
        autoClose: 3000,
      });
    }
  };

  return (
    <Modal
      isOpen={show}
      onClose={handleClose}
      motionProps={{
        variants: {
          enter: {
            opacity: 1,
            transition: {
              opacity: {
                duration: 0.6,
                ease: [0.36, 0.66, 0.4, 1],
              },
            },
          },
          exit: {
            opacity: 0,
            transition: {
              duration: 0.3,
              ease: [0.36, 0.66, 0.4, 1],
            },
          },
        },
      }}
      data-testid="image-crop-modal"
    >
      <Modal.ModalHeader>Crop Your Image</Modal.ModalHeader>

      <Modal.ModalBody>
        <div className="relative block w-full min-h-96">
          <Cropper
            objectFit="horizontal-cover"
            image={src}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={(_, cap) => setCroppedAreaPixels(cap)}
            mediaProps={{
              "data-testid": "image-crop-control",
            }}
            {...cropProps}
          />
        </div>

        <Slider
          label="Zoom"
          color="primary"
          value={zoom}
          onChange={setZoom}
          minValue={1}
          maxValue={3}
          step={0.1}
        />
      </Modal.ModalBody>

      <Modal.ModalActionsFooter
        onConfirm={handleCropImage}
        onCancel={handleClose}
        cancelProps={{
          "data-testid": "image-crop-modal-cancel",
        }}
        confirmProps={{
          "data-testid": "image-crop-modal-confirm",
        }}
      />
    </Modal>
  );
}
CropModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  aspect: PropTypes.number,
  show: PropTypes.bool,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
};
