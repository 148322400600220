import { useContext } from "react";
import { useInView } from "react-intersection-observer";
import { privateBriefNDA } from "features/brief/data/NDAData";
import { UserContext } from "features/user";
import { Modal } from "ui";
import briefPropTypes from "features/brief/schemas/briefSchema";
import Markdown from "react-markdown";
import PropTypes from "prop-types";

export default function PrivateBriefNDAModal({ onHide, onConfirm, brief, show = false }) {
  const { user } = useContext(UserContext);
  const { ref: scrolledRef, inView } = useInView();

  return (
    <Modal
      isOpen={show}
      onOpenChange={onHide}
      scrollBehavior="inside"
      data-testid="private-brief-nda-modal"
      size="xl"
    >
      <Modal.ModalHeader>Please read the terms of the brief</Modal.ModalHeader>

      <Modal.ModalBody>
        <Markdown className="old-markdown">{privateBriefNDA(brief, user).toString()}</Markdown>
        <div ref={scrolledRef} />
      </Modal.ModalBody>

      <Modal.ModalActionsFooter
        onConfirm={onConfirm}
        onCancel={onHide}
        confirmText="Accept"
        cancelText="Decline"
        confirmProps={{
          isDisabled: !inView,
        }}
      />
    </Modal>
  );
}
PrivateBriefNDAModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  brief: briefPropTypes.isRequired,
  show: PropTypes.bool,
};
