import { useState, useContext } from "react";
import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";
import { UserContext } from "features/user";
import { useSubmission } from "features/submission";
import FormControl from "forms/FormControl";
import { Modal, Avatar } from "ui";

import feedbackPropTypes from "../feedbackSchema";
import { useFeedbackMutations } from "../api";

const MIN_FEEDBACK_LENGTH = 10;
export default function FeedbackPromptModal({
  show,
  onSuccess,
  request = undefined,
  submissionId,
}) {
  const [feedbackText, setFeedbackText] = useState("");
  const { data: submission, isLoading } = useSubmission(submissionId);
  const { createFeedback } = useFeedbackMutations(submission?.id);
  const { user } = useContext(UserContext);

  const handleSubmitFeedback = () =>
    createFeedback.mutateAsync(
      {
        content: feedbackText,
        ...(request?.id && { parent: request?.id }),
      },
      {
        onSuccess,
      },
    );

  if (isLoading || Object.keys(submission).length === 0) return null;

  return (
    <Modal isOpen={show} onClose={onSuccess} data-testid="feedback-prompt-modal">
      <Modal.ModalBody className="p-6">
        <div className="flex justify-center">
          <Avatar
            src={submission.user?.icon}
            name={submission.user.username}
            size="lg"
            country={submission.user?.country_code}
            className="!size-20"
          />
        </div>

        <section className="flex flex-col my-4 text-center justify-center">
          <span className="text-3xl">
            Hi
            <span className="capitalize">{` ${user.given_name}` || ""}</span>, thanks for your vote!
          </span>
          <span className="uppercase text-primary-600">One more step</span>
        </section>

        <FormControl
          onUpdate={setFeedbackText}
          debounceTime={10}
          defaultValue={feedbackText}
          placeholder={`What did you love most about ${submission?.user.username.toUpperCase()}'s design? Help them out by leaving some feedback (min. 10 characters).`}
          name="feedbackText"
          as="textarea"
          rows={4}
          wrapperClassName="old-mb-3"
          minLength={MIN_FEEDBACK_LENGTH}
          maxLength={600}
        />

        <Modal.ModalActionsFooter
          onCancel={onSuccess}
          onConfirm={handleSubmitFeedback}
          cancelText="Skip"
          confirmText="Send Feedback"
          cancelProps={{
            variant: "light",
          }}
          confirmProps={{
            endContent: <SendIcon />,
            isDisabled: feedbackText.length < MIN_FEEDBACK_LENGTH || createFeedback.isPending,
            isLoading: createFeedback.isPending,
          }}
        />
      </Modal.ModalBody>
    </Modal>
  );
}

FeedbackPromptModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  request: feedbackPropTypes,
  submissionId: PropTypes.number.isRequired,
};
