import { useState, useRef, useContext } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { getNotificationPreferences } from "utils/helpers";
import { userEditSchema } from "utils/validation/validationSchemas";
import { Element } from "react-scroll";
import { InView } from "react-intersection-observer";
import { AuthContext } from "features/authentication";
import { useUpdateUser, UserContext } from "features/user";
import { PageLayout } from "features/navigation";
import { AddSocialModal, socialIcons, socialPrefixes } from "features/profile";
import ValidateForm, { ValidateUsername } from "forms/wrappers/ValidationWrappers";
import {
  Container,
  SectionLink,
  Row,
  Col,
  Form,
  ModalClickContainer,
  ConfirmationModal,
  Button,
} from "ui";
import routes from "default/routes";
import api from "adapters/api";
import FormControl from "forms/FormControl";
import FormSelect from "forms/FormSelect";
import FormDateTime from "forms/FormDateTime";
import CloseIcon from "@mui/icons-material/Close";
import FormFilePreviewAvatar from "forms/file/FormFilePreviewAvatar";
import iso3311a2 from "iso-3166-1-alpha-2";

const defaultSections = {
  icon: { inView: true, title: "Change Icon" },
  basic: { inView: false, title: "Basic Information" },
  about: { inView: false, title: "About" },
  links: { inView: false, title: "Links" },
  socials: { inView: false, title: "Socials" },
  marketing: { inView: false, title: "Notification Preferences" },
};

const deletedEntityKeys = {
  submission: "Submissions",
  comment: "Comments",
  vote: "Votes",
  submissionspotlight: "Spotlighted Submissions",
};

const socialsOrder = ["instagram", "tiktok", "facebook", "twitter", "youtube"];

export default function UserEdit() {
  const { user } = useContext(UserContext);
  const { mutateAsync } = useUpdateUser();
  const { logout } = useContext(AuthContext);
  const [sections, setSections] = useState(defaultSections);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedEntities, setDeletedEntities] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [socials, setSocials] = useState(user.metadata?.socials || {});
  const usernameValidRef = useRef(false);
  const navigate = useNavigate();

  const handleEditUser = async (formData) => {
    // We cant submit if the username is taken
    if (!usernameValidRef.current) return {};

    formData.metadata = {
      socials: Object.fromEntries(Object.entries(socials).filter(([, v]) => v !== "")),
      settings: {
        notification_preferences: getNotificationPreferences(
          formData,
          user.metadata.settings?.notification_preferences,
        ),
      },
    };

    if (formData.icon?.size <= 0) delete formData.icon;

    try {
      await mutateAsync(formData);
    } catch (error) {
      return {
        errors: {
          ...error.parseError(formData),
          api: error.details,
        },
      };
    }

    navigate(generatePath(routes.userProfile, { username: formData.username }));
    return {};
  };

  const deleteText = (entities) => (
    <>
      <p>
        <span className="old-text-danger">You are about to permanently delete your account.</span>{" "}
        This action is irreversible. Upon deletion, you will lose access to your account and all
        associated data.
      </p>
      {entities.length > 0 && (
        <>
          <p>The following data will be deleted:</p>
          <ul className="old-d-flex old-flex-column old-align-items-center old-text-left">
            {entities.map((entity) => (
              <li key={entity}>{entity}</li>
            ))}
          </ul>
        </>
      )}
      <p>This process cannot be undone. Are you sure you want to proceed?</p>
    </>
  );

  const prepareDeleteAccount = async () => {
    setDeleteLoading(true);
    const res = await api.delete({ url: "users/me" });
    if (res.success) {
      const entities = Object.keys(deletedEntityKeys)
        .map((entity) => {
          const count = res.data[entity];
          return count > 0 ? `${count} ${deletedEntityKeys[entity]}` : null;
        })
        .filter((entity) => entity);
      setDeleteLoading(false);
      setDeletedEntities(entities);
      setShowDeleteModal(true);
    }
  };

  const handleDeleteAccount = async () => {
    const res = await api.delete({ url: "users/me?force=true" });
    if (res.success) logout();
  };

  const setSectionInView = (section, inView) => {
    setSections({
      ...sections,
      [section]: { ...sections[section], inView },
    });
  };

  return (
    <PageLayout pageName={["Profile", "Edit"]}>
      <Container className="old-contain-paint old-sb-6 old-pt-4">
        <ValidateForm
          validationSchema={userEditSchema}
          onSubmit={handleEditUser}
          errorPage="profile"
        >
          {({ handleSubmit, errors, loading }) => (
            <Form onSubmit={handleSubmit} validated={false} noValidate autoComplete="off">
              <Row gap={6}>
                <Col xs={12} lg={4} className="old-mb-lg-3">
                  <div className="old-position-initial old-position-lg-sticky old-top-section-margin old-mb-6 old-mb-lg-3 old-fs-18 old-fs-md-20">
                    <div className="border-none old-border-top old-border-2 old-border-lightgrey">
                      <span className="uppercase font-bold old-text-lightgrey old-mt-4 old-mb-4 old-d-block old-fs-14">
                        Sections
                      </span>

                      <ul className="font-bold uppercase old-mb-5 old-mb-md-6">
                        {Object.keys(sections).map((section) => {
                          const sectionsInView = Object.keys(sections).filter(
                            (s) => sections[s].inView,
                          );
                          const inView = sectionsInView[sectionsInView.length - 1] === section;

                          return (
                            <SectionLink
                              key={section}
                              name={section}
                              title={sections[section].title}
                              inView={inView}
                            />
                          );
                        })}
                      </ul>
                    </div>

                    <div className="old-d-flex old-flex-column old-gap-3">
                      <Button
                        color="primary"
                        type="submit"
                        trackingName="edit user profile"
                        size="lg"
                        isLoading={loading}
                      >
                        Save and Return
                      </Button>

                      <Button
                        color="danger"
                        trackingName="delete user profile"
                        onClick={prepareDeleteAccount}
                        size="lg"
                        variant="flat"
                        isLoading={deleteLoading}
                      >
                        Delete Account
                      </Button>
                    </div>
                  </div>
                </Col>

                <Col xs={12} lg={8}>
                  <InView
                    as="div"
                    className="border-none old-border-top old-border-2 old-border-lightgrey old-mb-6 old-mb-lg-section-margin"
                    threshold={0.2}
                  >
                    <Element name="icon" />
                    <small className="uppercase font-bold old-text-lightgrey old-my-4 old-d-block">
                      {sections.icon.title}
                    </small>

                    <FormFilePreviewAvatar
                      name="icon"
                      defaultValue={user.icon}
                      error={errors.icon}
                      avatarProps={{
                        name: user.username,
                      }}
                    />
                  </InView>

                  <InView
                    as="div"
                    className="border-none old-border-top old-border-2 old-border-lightgrey old-mb-6 old-mb-lg-section-margin"
                    onChange={(inView) => setSectionInView("basic", inView)}
                    threshold={0.2}
                  >
                    <Element name="basic" />
                    <small className="uppercase font-bold old-text-lightgrey old-my-4 old-d-block">
                      {sections.basic.title}
                    </small>

                    <Row>
                      <Col xs={12} md={6}>
                        <ValidateUsername currentUsername={user.username} ref={usernameValidRef}>
                          {({ handleCheckUsername, usernameError }) => (
                            <FormControl
                              name="username"
                              aria-label="username"
                              label="Username"
                              debounceTime={100}
                              defaultValue={user.username || ""}
                              onUpdate={handleCheckUsername}
                              error={errors.username || usernameError}
                              maxLength={15}
                            />
                          )}
                        </ValidateUsername>
                      </Col>

                      <Col className="font-roman old-fs-12 old-d-flex old-align-items-center">
                        <ul>
                          <li>Your username cannot be longer than 20 characters.</li>
                          <li>
                            A username can only contain alphanumeric characters (letters A-Z,
                            numbers 0-9) with the exception of underscores.
                          </li>
                        </ul>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6}>
                        <FormControl
                          name="given_name"
                          label="First Name"
                          defaultValue={user.given_name || ""}
                          error={errors.given_name}
                        />
                      </Col>

                      <Col>
                        <FormControl
                          name="family_name"
                          label="Last Name"
                          defaultValue={user.family_name || ""}
                          error={errors.family_name}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6}>
                        <FormDateTime
                          name="dob"
                          label="Date of Birth"
                          timeFormat={false}
                          defaultValue={user.dob || ""}
                          error={errors.dob}
                        />
                      </Col>

                      <Col>
                        <FormSelect
                          name="gender"
                          label="Gender"
                          defaultValue={user.gender || ""}
                          error={errors.gender}
                        >
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="non_binary">Non Binary</option>
                          <option value="not_to_say">Prefer not to say</option>
                        </FormSelect>
                      </Col>
                    </Row>

                    <FormSelect
                      name="country_code"
                      label="Country"
                      defaultValue={user.country_code || ""}
                      error={errors.country_code}
                      showSearch
                    >
                      {Object.keys(iso3311a2.getData()).map((code) => (
                        <option key={code} value={code}>
                          {iso3311a2.getData()[code]}
                        </option>
                      ))}
                    </FormSelect>
                  </InView>

                  <InView
                    as="div"
                    className="border-none old-border-top old-border-2 old-border-lightgrey old-mb-6 old-mb-lg-section-margin"
                    onChange={(inView) => setSectionInView("about", inView)}
                    threshold={0.2}
                  >
                    <Element name="about" />
                    <small className="uppercase font-bold old-text-lightgrey old-my-4 old-d-block">
                      {sections.about.title}
                    </small>

                    <FormControl
                      name="description"
                      maxLength={600}
                      rows={10}
                      as="textarea"
                      defaultValue={user.description || ""}
                      error={errors.description}
                    />
                  </InView>

                  <InView
                    as="div"
                    className="border-none old-border-top old-border-2 old-border-lightgrey old-mb-6 old-mb-lg-section-margin"
                    onChange={(inView) => setSectionInView("links", inView)}
                    threshold={0.2}
                  >
                    <Element name="links" />
                    <small className="uppercase font-bold old-text-lightgrey old-my-4 old-d-block">
                      {sections.links.title}
                    </small>

                    <FormControl
                      placeholder="Add Website URL"
                      defaultValue={user.metadata.socials?.website || ""}
                      onUpdate={(newURL) => setSocials({ ...socials, website: newURL })}
                      error={errors.website}
                    />
                  </InView>

                  <InView
                    as="div"
                    className="border-none old-border-top old-border-2 old-border-lightgrey old-mb-6 old-mb-lg-section-margin"
                    onChange={(inView) => setSectionInView("socials", inView)}
                    threshold={0.2}
                  >
                    <Element name="socials" />
                    <small className="uppercase font-bold old-text-lightgrey old-my-4 old-d-block">
                      {sections.socials.title}
                    </small>

                    {socialsOrder.map((social) => {
                      const SocialIcon = socialIcons[social];
                      const provided = social in socials && socials[social];

                      return (
                        <div
                          className="bg-content2 old-rounded-4 old-p-2 old-w-100 old-d-flex old-justify-content-between old-align-items-center old-mb-3"
                          key={social}
                        >
                          <div className="old-d-flex old-align-items-center">
                            <SocialIcon className="!size-8 old-me-2" />
                            <span className="capitalize">{social}</span>
                          </div>

                          {!provided ? (
                            <ModalClickContainer
                              modal={AddSocialModal}
                              protectedAction
                              as={Button}
                              color="success"
                              size="sm"
                              modalProps={{
                                social,
                                onComplete: (newSocial) =>
                                  setSocials({
                                    ...socials,
                                    [social]: socialPrefixes[social] + newSocial,
                                  }),
                              }}
                            >
                              Link
                            </ModalClickContainer>
                          ) : (
                            <div>
                              <span className="old-text-lightgrey old-me-3">
                                {/^[^@]/.test(socials[social].split("/")[3]) ? "@" : ""}
                                {socials[social].split("/")[3]}
                              </span>

                              <CloseIcon
                                className="!size-8 old-rounded-circle old-bg-white old-p-2"
                                role="button"
                                onClick={() => setSocials({ ...socials, [social]: "" })}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </InView>

                  <InView
                    as="div"
                    className="border-none old-border-top old-border-2 old-border-lightgrey old-mb-6 old-mb-lg-section-margin"
                    onChange={(inView) => setSectionInView("marketing", inView)}
                    threshold={0.2}
                    id="marketing"
                  >
                    <Element name="marketing" />
                    <small className="uppercase font-bold old-text-lightgrey old-my-4 old-d-block">
                      {sections.marketing.title}
                    </small>

                    <Form.Group>
                      {Object.keys(user.metadata.settings.notification_preferences).map(
                        (notification) => {
                          const isChecked =
                            user.metadata.settings.notification_preferences[notification];
                          return (
                            <Form.Check
                              name={notification}
                              type="checkbox"
                              key={notification}
                              id={notification}
                              label={notification.replace(/_/g, " ")}
                              defaultChecked={isChecked}
                              className="old-mb-3 uppercase font-bold old-fs-14 old-form-check-terms old-d-flex old-align-items-center"
                            />
                          );
                        },
                      )}
                    </Form.Group>
                  </InView>

                  <Button
                    color="primary"
                    size="lg"
                    isLoading={loading}
                    trackingName="edut user profile"
                    type="submit"
                    fullWidth
                    className="mb-12 uppercase"
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </ValidateForm>
      </Container>

      <ConfirmationModal
        show={showDeleteModal}
        promptText={deleteText(deletedEntities)}
        confirmText="Delete Everything"
        onHide={() => setShowDeleteModal(false)}
        action={handleDeleteAccount}
        cancelProps={{
          color: "primary",
          variant: "solid",
        }}
        confirmProps={{
          color: "danger",
          variant: "flat",
        }}
      />
    </PageLayout>
  );
}
