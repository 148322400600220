import { useQuery, client } from "api";

const baseURL = "submissions/spotlight";

const spotlightQueries = {
  all: () => [{ url: baseURL }],
  lists: () => [{ url: baseURL, view: "list" }],
  list: (params) => [{ url: baseURL, view: "list", params }],
  details: () => [{ url: baseURL, view: "detail" }],
  detail: (id) => [{ url: baseURL, view: "detail", id: id == null ? id : String(id) }],
};

const useSpotlight = () =>
  useQuery({
    queryKey: spotlightQueries.list(),
    queryFn: async ({ queryKey: [{ url }] }) => (await client.get(url)).data,
    select: (data) =>
      data.map((spotlight) => ({
        ...spotlight.submission,
        created_at: spotlight.created_at,
      })),
  });

export { useSpotlight, spotlightQueries };
