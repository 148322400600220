import { useState } from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import IconButton from "ui/buttons/IconButton";
import CardBody from "ui/cards/body/CardBody";
import SimpleCardBody from "ui/cards/body/SimpleCardBody";

function DefaultOverlay() {
  return (
    <div className="old-rounded-4">
      <div className="old-position-absolute old-bottom-0 old-end-0 old-mb-3 old-me-3 !size-8 sm:!size-10 old-bg-transparent">
        <IconButton.Arrow className="old-bg-primary old-rounded-circle old-p-1 old-shadow old-text-secondary" />
      </div>
    </div>
  );
}

function BlankImageCard({ header, dataTestIdName = "image-card", className = "" }) {
  const Header = header;

  return (
    <div
      className={`old-d-flex old-flex-column old-h-100 old-bg-blank old-rounded-4 ${className}`}
      data-testid={dataTestIdName}
    >
      <div className="invisible">
        <Header blank />
      </div>

      <div className="old-overflow-hidden old-d-flex old-position-relative old-h-100">
        <div className="old-w-100 old-ratio old-ratio-4x3">
          <img
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            className="old-w-100"
            alt="blank card"
          />
        </div>
      </div>
    </div>
  );
}
BlankImageCard.propTypes = {
  header: PropTypes.elementType.isRequired,
  dataTestIdName: PropTypes.string,
  className: PropTypes.string,
};

function ImageCardImage({ image = "", dataTestIdName = "image-card" }) {
  const [loaded, setLoaded] = useState(false);

  // If no image exists lets show a default
  return (
    <>
      {image && (
        <img
          src={image}
          onLoad={() => setLoaded(true)}
          className={`old-w-100 old-rounded-4 old-object-fit-cover old-bg-white ${!loaded ? "old-d-none" : ""}`}
          data-testid={`${dataTestIdName}-image`}
          alt="card"
        />
      )}

      {(!image || !loaded) && (
        <div
          className="old-bg-offwhite old-d-flex old-align-items-center old-justify-content-center old-rounded-4 old-h-100"
          data-testid={`${dataTestIdName}-image`}
        >
          <ImageNotSupportedOutlinedIcon className="!size-12 old-text-muted" />
        </div>
      )}
    </>
  );
}
ImageCardImage.propTypes = {
  image: PropTypes.string,
  dataTestIdName: PropTypes.string,
};

function OptionalClick({ onClick = undefined, dataTestIdName = "image-card", children }) {
  if (onClick) {
    return (
      <div
        onClick={onClick}
        role="button"
        tabIndex={0}
        data-testid={`${dataTestIdName}-button`}
        className="old-d-flex old-flex-column old-h-100 old-justify-content-between"
      >
        {children}
      </div>
    );
  }

  return (
    <div
      data-testid={`${dataTestIdName}-button`}
      className="old-d-flex old-flex-column old-h-100 old-justify-content-between"
    >
      {children}
    </div>
  );
}
OptionalClick.propTypes = {
  onClick: PropTypes.func,
  dataTestIdName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function ImageCard({
  image = "",
  onClick = undefined,
  blank = false,
  overlay = DefaultOverlay,
  header = () => {},
  footer = () => {},
  name = "image",
  className = "",
  overlayProps = {},
  ...bodyProps
}) {
  const ImageOverlay = overlay;
  const Header = header;
  const Footer = footer;
  const dataTestIdName = `${name}-card`;

  if (blank)
    return <BlankImageCard header={Header} className={className} dataTestIdName={dataTestIdName} />;

  return (
    <div
      className={twMerge(
        "old-position-relative old-rounded-4 old-overflow-hidden old-h-100 bg-content4 text-primary-foreground",
        className,
      )}
      data-testid={dataTestIdName}
    >
      <OptionalClick onClick={onClick} dataTestIdName={dataTestIdName}>
        <Header {...bodyProps} />
        <div className="old-overflow-hidden old-d-flex old-position-relative">
          <div className="old-w-100 old-ratio old-ratio-4x3">
            <ImageCardImage image={image} dataTestIdName={dataTestIdName} />
            <ImageOverlay {...overlayProps} />
          </div>
        </div>
        <Footer {...bodyProps} />
      </OptionalClick>
    </div>
  );
}

ImageCard.propTypes = {
  image: PropTypes.string,
  onClick: PropTypes.func,
  blank: PropTypes.bool,
  overlay: PropTypes.elementType,
  header: PropTypes.elementType,
  footer: PropTypes.elementType,
  name: PropTypes.string,
  className: PropTypes.string,
  overlayProps: PropTypes.shape({}),
};

CardBody.displayName = "CardBody";
SimpleCardBody.displayName = "SimpleCardBody";
ImageCard.Body = CardBody;
ImageCard.SimpleBody = SimpleCardBody;

export default ImageCard;
