import { useMany } from "api";

const baseURL = "leaderboard/";

const leaderboardQueries = {
  all: () => [{ base: baseURL }],
  lists: () => [{ base: baseURL, view: "list" }],
  list: (leaderboard, params) => [
    { base: baseURL, view: "list", url: baseURL + leaderboard, params },
  ],
};

const useLeaderboard = (leaderboard, params) =>
  useMany({ queryKey: leaderboardQueries.list(leaderboard, params) });
const useCountryLeaderboard = (leaderboard) =>
  useMany({ queryKey: leaderboardQueries.list(`${leaderboard}/country`) });

export { useLeaderboard, useCountryLeaderboard };
