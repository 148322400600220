import { generatePath, Link } from "react-router-dom";
import { Modal, Listbox, ListboxItem, User, Button } from "ui";
import routes from "default/routes";
import PropTypes from "prop-types";

import useSubmissionInsights from "../../../hooks/useSubmissionInsights";

export default function SubmissionVotesModal({ submissionId, onHide, show = false }) {
  const { voters, isLoading } = useSubmissionInsights(submissionId);

  return (
    <Modal
      isOpen={show}
      onOpenChange={onHide}
      data-testid="submission-votes-modal"
      scrollBehavior="outside"
      classNames={{
        base: "overflow-y-visible p-3",
        closeButton: "-mt-10 bg-content1 shadow-2xl",
      }}
    >
      {!isLoading ? (
        <>
          <h4 className="text-center mt-1">Your Voters</h4>
          <Listbox
            aria-label="Voter List"
            items={voters}
            className="w-full items-center content-between overflow-hidden"
          >
            {(voter) => (
              <ListboxItem
                key={voter.user.id}
                as={Link}
                to={generatePath(routes.userProfile, {
                  username: voter.user.username,
                })}
              >
                <div className="flex w-full justify-between items-center content-between overflow-hidden">
                  <User
                    avatarProps={{
                      src: voter.user.icon,
                    }}
                    name={voter.user.username}
                    description={
                      voter.type === "fellow_participant" ? "Also entered this brief!" : ""
                    }
                    badges={voter.user.badges}
                    className="bg-inherit px-0 md:px-2"
                  />
                  {voter.type === "fellow_participant" && (
                    <Button
                      as={Link}
                      color="primary"
                      variant="ghost"
                      size="sm"
                      to={generatePath(routes.userProfileSubmission, {
                        username: voter.user.username,
                        submissionId: voter.fellow_participant,
                      })}
                      trackingName="view fellow design"
                    >
                      View Design
                    </Button>
                  )}
                </div>
              </ListboxItem>
            )}
          </Listbox>
        </>
      ) : (
        <div className="old-mt-3">
          <User.Loading />
          <User.Loading />
        </div>
      )}
    </Modal>
  );
}

SubmissionVotesModal.propTypes = {
  submissionId: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
