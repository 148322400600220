import { briefStatusContent } from "features/brief/data/briefData";
import briefPropTypes from "features/brief/schemas/briefSchema";
import Countdown from "react-countdown";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PropTypes from "prop-types";

function CountdownRenderer({ days, hours, minutes, completed }) {
  return (
    <div
      className={`old-d-flex old-text-white uppercase font-roman old-flex-wrap ${completed ? "invisible" : "visible"}`}
      data-testid="countdown"
    >
      <b className="old-me-3 old-mb-0 old-fs-xl-5 old-fs-6">
        {days} {days === 1 ? "Day" : "Days"}
      </b>
      <b className="old-me-3 old-mb-0 old-fs-xl-5 old-fs-6">
        {hours} {hours === 1 ? "Hour" : "Hours"}
      </b>
      <b className="old-mb-0 old-fs-xl-5 old-fs-6">{minutes} Mins</b>
    </div>
  );
}
CountdownRenderer.propTypes = {
  days: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  minutes: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
};

function BriefStatus({ brief, ...props }) {
  const { status, deadline } = briefStatusContent(brief)[brief.status];

  return (
    <div
      className="old-d-flex old-align-items-center old-bg-lightgrey old-rounded-2 old-bg-opacity-25 old-p-3 old-w-100"
      {...props}
    >
      <div className="old-me-3">
        <h4 className="old-fs-lg-5 old-fs-6 old-d-inline-block old-me-5 old-my-0">{status}</h4>
        {status !== "Accepting New Submissions" && (
          <div className="old-d-flex old-align-items-center old-mt-1">
            <Countdown
              date={deadline ? brief[deadline] : Date.now()}
              renderer={CountdownRenderer}
            />
          </div>
        )}
      </div>
      <div className="old-d-flex old-justify-content-end old-ms-auto">
        <HelpOutlineIcon className="!size-8" />
      </div>
    </div>
  );
}

BriefStatus.propTypes = {
  brief: briefPropTypes.isRequired,
};

export default BriefStatus;
