// helper function to recursively update the like state for a comment by ID
const updateLikeState = (comments, commentId, newLiked) =>
  comments.map((comment) => {
    if (comment.id === commentId) {
      return {
        ...comment,
        liked_by_user: newLiked,
        like_count: newLiked ? comment.like_count + 1 : comment.like_count - 1,
      };
    }
    // Recursively update the children (replies) if they exist - i'll remove when replies are own api call
    if (comment.children && comment.children.length > 0) {
      return {
        ...comment,
        children: updateLikeState(comment.children, commentId, newLiked),
      };
    }
    return comment;
  });

export default updateLikeState;
