import PropTypes from "prop-types";

const smallUserPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  icon: PropTypes.string,
  username: PropTypes.string.isRequired,
});

const userPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  icon: PropTypes.string, // not required as user might not have icon
  username: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  feedback_given_count: PropTypes.number.isRequired,
  feedback_received_count: PropTypes.number.isRequired,
  follower_count: PropTypes.number.isRequired,
  following_count: PropTypes.number.isRequired,
  following: PropTypes.bool.isRequired,
  metadata: PropTypes.shape({
    socials: PropTypes.objectOf(PropTypes.string),
  }),
});

const publicUserPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  icon: PropTypes.string,
  username: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  follower_count: PropTypes.number.isRequired,
  following_count: PropTypes.number.isRequired,
  following: PropTypes.bool.isRequired,
  metadata: PropTypes.shape({
    socials: PropTypes.objectOf(PropTypes.string),
  }),
});

const userListPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  icon: PropTypes.string,
  username: PropTypes.string.isRequired,
});

export default userPropTypes;
export { userListPropTypes, publicUserPropTypes, smallUserPropTypes };
