import { useMemo } from "react";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import { submissionSpotlightPropTypes } from "features/submission/schemas/submissionSchema";
import { UserBadgeList } from "features/user";
import { Row, Col, Container, IconButton, Avatar } from "ui";
import routes from "default/routes";

export default function SpotlightSubmission({ spotlight, number }) {
  const navigate = useNavigate();
  const { ref, inView, entry } = useInView({ threshold: 0.8 });
  const reverseScroll = useMemo(() => (entry ? entry.boundingClientRect.top < 0 : false), [entry]);

  return (
    <div
      className="relative h-screen old-contain-paint"
      onClick={() =>
        navigate(
          generatePath(routes.spotlightSubmission, {
            submissionId: spotlight?.id,
          }),
          {
            state: {
              scrollToTop: false,
            },
          },
        )
      }
      role="button"
      tabIndex={0}
    >
      {/* 16 x 9 thumbnail */}
      <img
        src={
          spotlight.images?.map((image) => image.image.compressed || image.image.original)[0].url
        }
        className="size-full aspect-video hidden lg:block object-cover"
        alt={`${spotlight.images?.map((image) => image.image.compressed || image.image.original)[0].name} 16x9`}
      />

      {/* 4 x 3 thumbnail */}
      <img
        src={
          spotlight.images?.map((image) => image.image.compressed || image.image.original)[0].url
        }
        className="size-full aspect-4/3 old-d-lg-none object-cover"
        alt={`${spotlight.images?.map((image) => image.image.compressed || image.image.original)[0].name} 4x3`}
      />

      <div ref={ref} className="absolute top-0 size-full old-overlay-fade-end">
        <Container
          className={`py-32 px-8 transition-all duration-500 ${inView ? "opacity-100" : `opacity-0 ${reverseScroll ? "-translate-y-full" : "translate-y-full"}`}`}
        >
          <Row className="relative old-h-100">
            <Col xs={12} lg={4} className="flex flex-col gap-12 justify-center">
              <div>
                <Avatar
                  src={spotlight.user?.icon}
                  name={spotlight.user.username}
                  country={spotlight.user?.country_code}
                  className="size-12 md:size-16 mb-5"
                />

                <div className="flex items-center">
                  <Link
                    to={generatePath(routes.userProfile, {
                      username: spotlight.user?.username,
                    })}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h3 className="mb-0 uppercase old-transition-underline old-fs-5 old-fs-md-3">
                      {spotlight.user?.username}
                    </h3>
                  </Link>
                  <UserBadgeList className="!size-8 ms-2" badges={spotlight.user.badges} />
                </div>

                <small className="font-bold old-text-lightgrey uppercase old-position-relative old-fs-12 old-fs-md-14">
                  People
                </small>
              </div>

              <div>
                <Avatar
                  src={spotlight.brief.organisation?.icon}
                  name={spotlight.brief.organisation?.name}
                  className="size-12 md:size-16 mb-5"
                />

                <div className="flex items-center">
                  <Link
                    to={generatePath(routes.organisation, {
                      id: spotlight.brief?.organisation.id,
                    })}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h3 className="old-mb-0 uppercase old-transition-underline old-fs-5 old-fs-md-3">
                      {spotlight.brief?.organisation.name}
                    </h3>
                  </Link>
                </div>

                <small className="font-bold old-text-lightgrey uppercase old-position-relative old-fs-12 old-fs-md-14">
                  Organisation
                </small>
              </div>
            </Col>

            <Col xs={12} lg={8} className="flex lg:flex-col lg:justify-center">
              <div className="self-start">
                <small className="uppercase old-position-relative">{spotlight.brief?.title}</small>
                <h2 className="old-mb-6 old-h2">{spotlight.title}</h2>

                <h1 className="font-signature capitalize">
                  {spotlight.user?.username.replace("_", " ")}
                </h1>
              </div>
            </Col>

            <div className="absolute bottom-0 right-0 flex items-center">
              <span className="text-5xl md:text-6xl">#{number.toString().padStart(3, "0")}</span>
              <div className="size-8 md:size-12 animate-bounce">
                <IconButton.Arrow className="rotate-90" iconClassName="text-primary-foreground" />
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}

SpotlightSubmission.propTypes = {
  spotlight: submissionSpotlightPropTypes.isRequired,
  number: PropTypes.number.isRequired,
};
