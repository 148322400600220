import { useOne, useMany, useMutation, client, useQueryClient } from "api";

const baseURL = "briefs/";

const briefQueries = {
  all: () => [{ url: baseURL }],
  lists: () => [{ url: baseURL, view: "list" }],
  list: (params) => [{ url: baseURL, view: "list", params }],
  details: () => [{ url: baseURL, view: "detail" }],
  detail: (id) => [{ url: baseURL, view: "detail", id: id == null ? id : String(id) }],
};

const useBriefs = (params) => useMany({ queryKey: briefQueries.list(params) });
const useBrief = (id) => useOne({ queryKey: briefQueries.detail(id) });

const useEnterBrief = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => client.put(`briefs/${id}/enter/`),
    onSuccess: ({ data: brief }) => {
      queryClient.setQueryData(briefQueries.detail(brief.id), brief);
      queryClient.invalidateQueries({
        queryKey: briefQueries.detail(brief.id),
      });
    },
  });
};

export { useBrief, useBriefs, useEnterBrief, briefQueries };
