import { generatePath as domGeneratePath } from "react-router-dom";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjs.extend(relativeTime);
dayjs.extend(utc);

/*
    ------------------------------------------------------------------------
    An Assortment of helper functions for use throughout the application(s).
    ------------------------------------------------------------------------
*/

/*
    Function:   abbreviateNumber
    desc:       abbreviate a number to a fixed number of decimal points

    params:
    number:     int - the number to abbreviate
    decimals:   int - the number of decimal points to abbreviate to
*/
const abbreviateNumber = (number, decimals) => {
  if (number === undefined) return 0;
  if (number < 1000) return number;

  let x = `${number}`.length;
  decimals = 10 ** decimals;
  x -= x % 3;
  return Math.round(number * (decimals / 10 ** x)) / decimals + " kMGTPE"[x / 3];
};

const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const objectUrlToFile = async (objecturl, fileName) => {
  const blob = await (await fetch(objecturl)).blob();
  return new File([blob], fileName, { type: blob.type });
};

const getNotificationPreferences = (formData, notificationPreferences) => {
  const newNotificationPreferences = {};
  Object.keys(notificationPreferences).forEach((key) => {
    newNotificationPreferences[key] = Boolean(formData[key]);
    delete formData[key];
  });
  return newNotificationPreferences;
};

const getFileProps = (file) =>
  new Promise((resolve, reject) => {
    if (file.size <= 0) reject(new Error("file not found"));

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const thumbnail = new Image();

      thumbnail.addEventListener("load", () => resolve(thumbnail));

      thumbnail.src = reader.result;
    });
    reader.addEventListener("error", (e) => reject(e));
    reader.readAsDataURL(file);
  });

const readFile = (files) => {
  if (!(files instanceof File)) return Promise.resolve(files);

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result?.toString() || ""));
    reader.addEventListener("error", (e) => reject(e));
    reader.readAsDataURL(Array.from(files).length > 0 ? files[0] : files);
  });
};

const writeFilesToInput = (files, input) => {
  if (!input || files.length <= 0) return;

  const list = new DataTransfer();
  files.forEach((file) => {
    if (!(file instanceof File)) return;
    list.items.add(file);
  });
  input.files = list.files;
};

// Override react-router-dom generate path to allow for matching wildcard cases
const generatePath = (path, match) => {
  if (match && "wildcard" in match) path = path.replace("*", match.wildcard);
  return domGeneratePath(path, match);
};

const redirectDocument = (url, params = {}) => {
  const splat = params["*"];
  const redirectUrl = splat ? [url, splat].join("/") : url; // Only append splat if present
  window.location.replace(redirectUrl + window.location.search);
  return null;
};

const stringifyFormData = (formData, ignoreKeys = []) => {
  const shouldStringify = (key) => !ignoreKeys.includes(key.toString());
  return Object.fromEntries(
    Object.entries(formData).map(([k, v]) => [k, shouldStringify(k) ? JSON.stringify(v) : v]),
  );
};

/**
 * TODO: in the future we want to make use of MSW v2.0 and the request.formData() function
 * - This is currently not possible as MSW v2.0 and undici v6/v5 are not compatable
 *
 * This is a workaround to parse the formdata stream and return an object of { key: value } pairs.
 * All values will be in string format but can be parsed where used locally.
 *
 * hours wasted here: 10 <-- please update if you try to use msw instead.
 */
const decodeArrayBufferFormData = (formData) => {
  formData = formData.filter((s) => typeof s !== "function");
  const mappings = {};

  formData.forEach((value, index) => {
    if (index % 2 === 0) return;

    const key = formData[index - 1]
      .split("name=")[1]
      .replace(/(\r\n|\n|\r)/gm, "")
      .replace(/['"]+/g, "");

    try {
      mappings[key] = JSON.parse(value);
    } catch (err) {
      mappings[key] = value.replace(/['"]+/g, "");
    }
  });
  return mappings;
};

const calculateDaysLeft = (deadline) => {
  const deadlineTime = dayjs(deadline);
  const currentTime = dayjs();
  if (currentTime > deadlineTime) return null;

  const daysLeft = deadlineTime.diff(currentTime, "day");
  if (daysLeft === 0) return "Last Day";
  if (daysLeft > 0 && daysLeft < 6) return `${daysLeft} ${daysLeft > 1 ? "days" : "day"}`;
  return null;
};

function formatRelativeTime(timestamp) {
  const postTime = dayjs.utc(timestamp);
  return postTime.fromNow();
}

export {
  abbreviateNumber,
  formatBytes,
  objectUrlToFile,
  getNotificationPreferences,
  getFileProps,
  readFile,
  writeFilesToInput,
  generatePath,
  redirectDocument,
  stringifyFormData,
  decodeArrayBufferFormData,
  calculateDaysLeft,
  formatRelativeTime,
};
