import { useContext } from "react";
import PropTypes from "prop-types";

import { UserContext } from "features/user";
import { useSubmission } from "features/submission";

import { useFeedback } from "../api";
import FeedbackCard from "./FeedbackCard/FeedbackCard";

export default function FeedbackList({ submissionId, handleReply }) {
  const { data: feedback = { comments: [] }, isPending, isError } = useFeedback(submissionId);
  const { data: submission } = useSubmission(submissionId);
  const { user } = useContext(UserContext);

  if (isPending) return <FeedbackCard.Loading />;
  if (isError) return <div className="text-center text-red-500">Failed to load comments</div>;

  if (feedback.comments.length === 0) return null;

  return (
    <>
      <div className="bg-default-100 flex items-center justify-center gap-2 rounded-xl p-4 mb-4 text-center w-full">
        <span className="text-md sm:text-xl uppercase font-semibold text-primary-200 m-0 inline">
          Comments ({feedback.comments.length})
        </span>
      </div>

      {feedback.comments.map((comment) => (
        <div key={comment.id} className="mb-4">
          <FeedbackCard
            comment={comment}
            onReply={handleReply}
            submissionId={submissionId}
            isOwnSubmission={submission.user.id === user.id}
          />
        </div>
      ))}
    </>
  );
}
FeedbackList.propTypes = {
  submissionId: PropTypes.number.isRequired,
  handleReply: PropTypes.func.isRequired,
};
