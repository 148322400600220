import { useContext } from "react";
import { userOnboardingSchema } from "utils/validation/validationSchemas";
import { useUpdateUser, UserContext } from "features/user";
import { Row, Col, Form, Button } from "ui";
import ValidateForm from "forms/wrappers/ValidationWrappers";
import FormControl from "forms/FormControl";
import FormSelect from "forms/FormSelect";
import FormDateTime from "forms/FormDateTime";
import PropTypes from "prop-types";
import iso3311a2 from "iso-3166-1-alpha-2";

export default function OnboardingUser({ onComplete }) {
  const { user } = useContext(UserContext);
  const { mutateAsync } = useUpdateUser();

  const handleUpdateUserInformation = async (formData) => {
    try {
      await mutateAsync(formData);
    } catch (error) {
      return {
        errors: {
          ...error.parseError(formData),
          api: error.details,
        },
      };
    }

    onComplete();
    return {};
  };

  return (
    <div data-testid="user-onboarding-user">
      <h2 className="capitalize old-mb-5 old-fs-1">Tell us about yourself</h2>

      <ValidateForm
        onSubmit={handleUpdateUserInformation}
        validationSchema={userOnboardingSchema}
        scrollOnSubmit={false}
        errorPage="onboarding"
      >
        {({ handleSubmit, errors, loading }) => (
          <Form onSubmit={handleSubmit} validated={false} noValidate autoComplete="off">
            <Row>
              <Col xs={12} md={6}>
                <FormControl
                  name="given_name"
                  aria-label="given_name"
                  placeholder="First Name"
                  label="First Name"
                  wrapperClassName="old-mb-3"
                  defaultValue={user.given_name || ""}
                  error={errors.given_name}
                  key={`given_name${user.given_name}`}
                />
              </Col>

              <Col>
                <FormControl
                  name="family_name"
                  aria-label="family_name"
                  placeholder="Last Name"
                  label="Last Name"
                  wrapperClassName="old-mb-3"
                  defaultValue={user.family_name || ""}
                  error={errors.family_name}
                  key={`family_name${user.family_name}`}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <FormDateTime
                  name="dob"
                  label="Date of Birth"
                  aria-label="dob"
                  wrapperClassName="old-mb-3"
                  timeFormat={false}
                  error={errors.dob}
                  defaultValue={user.dob || ""}
                  key={`dob${user.dob}`}
                />
              </Col>

              <Col>
                <FormSelect
                  name="gender"
                  label="Gender"
                  wrapperClassName="old-mb-3"
                  defaultValue={user.gender || ""}
                  error={errors.gender}
                  key={`gender${user.gender}`}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="non_binary">Non Binary</option>
                  <option value="not_to_say">Prefer not to say</option>
                </FormSelect>
              </Col>
            </Row>

            <FormSelect
              name="country_code"
              label="Country"
              defaultValue={user.country_code || ""}
              error={errors.country_code}
              key={`country${user.country_code}`}
              showSearch
            >
              {Object.keys(iso3311a2.getData()).map((code) => (
                <option key={code} value={code}>
                  {iso3311a2.getData()[code]}
                </option>
              ))}
            </FormSelect>

            <Button
              type="submit"
              color="primary"
              isLoading={loading}
              trackingName="continue onboarding"
              fullWidth
            >
              Continue
            </Button>
          </Form>
        )}
      </ValidateForm>
    </div>
  );
}

OnboardingUser.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
