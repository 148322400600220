import { useOne, useMany, useQuery, useQueryClient, useMutation, client } from "api";
import { briefQueries } from "features/brief";

const baseURL = "submissions/";

const submissionQueries = {
  all: () => [{ url: baseURL }],
  lists: () => [{ url: baseURL, view: "list" }],
  list: (params) => [{ url: baseURL, view: "list", params }],
  details: () => [{ url: baseURL, view: "detail" }],
  detail: (id) => [{ url: baseURL, view: "detail", id: id == null ? id : String(id) }],
  voters: (id) => [
    {
      url: baseURL,
      view: "detail",
      id: id == null ? id : String(id),
      type: "voters",
    },
  ],
};

const useSubmissions = (params) => useMany({ queryKey: submissionQueries.list(params) });
const useSubmission = (id) => useOne({ queryKey: submissionQueries.detail(id) });
const useSubmissionVoters = (id) =>
  useQuery({
    queryKey: submissionQueries.voters(id),
    queryFn: async ({ queryKey: [{ url }] }) => {
      const response = await client.get(`${url}${id}/voters/`);
      return response.data;
    },
  });

const useSubmissionMutations = () => {
  const queryClient = useQueryClient();
  return {
    createSubmission: useMutation({
      mutationFn: ({ briefId, data }) =>
        client.post(`briefs/${briefId}/submissions`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        }),
      onSuccess: ({ data: submission }) => {
        queryClient.setQueryData(submissionQueries.detail(submission.id), submission);
        queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
        queryClient.invalidateQueries({
          queryKey: briefQueries.detail(submission.brief.id),
        });
      },
    }),
    updateSubmission: useMutation({
      mutationFn: ({ id, data }) =>
        client.patch(`submissions/${id}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        }),
      onSuccess: ({ data: submission }) => {
        queryClient.setQueryData(submissionQueries.detail(submission.id), submission);
        queryClient.invalidateQueries({ queryKey: submissionQueries.lists() });
        queryClient.invalidateQueries({
          queryKey: briefQueries.detail(submission.brief.id),
        });
      },
    }),
  };
};

export {
  useSubmission,
  useSubmissions,
  useSubmissionVoters,
  useSubmissionMutations,
  submissionQueries,
};
