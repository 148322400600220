import { useMemo } from "react";
import iso3311a2 from "iso-3166-1-alpha-2";
import { useSubmission, useSubmissionVoters } from "features/submission";

const THRESHOLDS = [
  { threshold: 0.99, label: "Top 1%" },
  { threshold: 0.95, label: "Top 5%" },
  { threshold: 0.9, label: "Top 10%" },
  { threshold: 0.85, label: "Top 15%" },
  { threshold: 0.8, label: "Top 20%" },
  { threshold: 0.75, label: "Top 25%" },
  { threshold: 0.7, label: "Top 30%" },
];

const getFlagEmoji = (countryCode) =>
  [...countryCode.toUpperCase()]
    .map((char) => String.fromCodePoint(127397 + char.charCodeAt()))
    .reduce((a, b) => `${a}${b}`);

const asPercentage = (value, total) =>
  Number(value / total).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0,
  });

const sumObjectValues = (obj) => Object.values(obj).reduce((sum, c) => sum + c, 0);

const calculatePercentile = (value) => {
  const result = THRESHOLDS.find((item) => value >= item.threshold);
  return result ? result.label : undefined;
};

const voterType = (voter) => {
  if (voter.fellow_participant) return "fellow_participant";
  if (voter.user.achievements.find((a) => a.name === "briefs_completed" && a.progress >= 1))
    return "designer";
  return "voter";
};

const useSubmissionInsights = (id) => {
  const { data: submission, isLoading: isLoadingSubmission } = useSubmission(id);
  const { data: votes, isLoading: isLoadingVotes } = useSubmissionVoters(id);

  const voters = useMemo(() => {
    if (!votes) return [];
    const orderedVotes = votes.sort((v1, v2) => v2.fellow_participant - v1.fellow_participant);
    return orderedVotes.map((voter) => ({ ...voter, type: voterType(voter) }));
  }, [votes]);

  const percentiles = useMemo(() => {
    if (!submission) return {};

    return {
      votePercentile: calculatePercentile(submission.ranks.vote_percentile),
      pointPercentile: calculatePercentile(submission.ranks.point_percentile),
    };
  }, [submission]);

  const breakdowns = useMemo(() => {
    if (!votes) return {};

    const countryCounts = votes.reduce((acc, { user: { country_code: code } }) => {
      if (code) acc[code] = (acc[code] || 0) + 1;
      return acc;
    }, {});
    const countryTotal = sumObjectValues(countryCounts);
    const countryBreakdown = Object.keys(countryCounts)
      .map((country) => ({
        label: getFlagEmoji(country),
        tooltip: iso3311a2.getCountry(country) ?? country,
        value: countryCounts[country] / countryTotal,
        display: asPercentage(countryCounts[country], countryTotal),
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 5);

    const genderCounts = votes.reduce((acc, { user: { gender } }) => {
      if (gender) acc[gender] = (acc[gender] || 0) + 1;
      return acc;
    }, {});
    const genderTotal = sumObjectValues(genderCounts);
    const genderBreakdown = [
      {
        label: "🙋‍♂️",
        tooltip: "Male",
        value: genderCounts.male || 0,
        display: asPercentage(genderCounts.male || 0, genderTotal),
      },
      {
        label: "💁‍♀️",
        tooltip: "Female",
        value: genderCounts.female || 0,
        display: asPercentage(genderCounts.female || 0, genderTotal),
      },
      {
        label: "👱",
        tooltip: "Non-binary",
        value: genderCounts.non_binary || 0,
        display: asPercentage(genderCounts.non_binary || 0, genderTotal),
      },
    ]
      .filter(({ value }) => value)
      .sort((a, b) => b.value - a.value);

    return { genderBreakdown, countryBreakdown };
  }, [votes]);

  return {
    isLoading: isLoadingVotes || isLoadingSubmission,
    voters,
    ...breakdowns,
    ...percentiles,
  };
};

export default useSubmissionInsights;
