import { useState, useRef } from "react";
import { ImageCard, CropModal } from "ui";
import { writeFilesToInput, objectUrlToFile } from "utils/helpers";
import { twMerge } from "tailwind-merge";
import FormFile from "forms/file/FormFile";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";

function RemovePreviewOverlay({ onDelete }) {
  return (
    <div className="old-w-100">
      <div className="old-position-absolute old-top-2 old-start-2" data-testid="remove-preview">
        <CloseIcon
          className="old-bg-white old-rounded-circle old-text-black old-p-1 !size-8 old-shadow"
          role="button"
          onClick={onDelete}
        />
      </div>
    </div>
  );
}
RemovePreviewOverlay.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

function PreviewImage({ acceptedFileTypes, className, isDragReject }) {
  return (
    <div
      className={`border border-dashed border-2 border-foreground old-p-5 old-w-100 old-d-flex old-flex-column old-align-items-center old-justify-content-center old-gap-2 aspect-4/3 transition-colors duration-300 ease-in-out ${className} ${isDragReject ? "old-bg-danger old-text-white" : ""}`}
      data-role="form-error-component"
      role="button"
      tabIndex={0}
    >
      <div className="old-d-inline-flex old-align-items-center old-mx-auto">
        <AddIcon className="old-me-1" />
        <span className="uppercase">Add New Image</span>
      </div>
      <p className="text-primary-300 old-d-block old-mb-0 old-mw-brief-toggle">
        {acceptedFileTypes()}
      </p>
    </div>
  );
}
PreviewImage.propTypes = {
  acceptedFileTypes: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  isDragReject: PropTypes.bool.isRequired,
};

function FormFilePreviewImage({
  defaultValue = null,
  onUpdate = undefined,
  cropProps = {},
  ...fileProps
}) {
  const [previewSrc, setPreviewSrc] = useState(defaultValue);
  const [src, setSrc] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const fileRef = useRef(null);

  const handleSuccess = async (croppedSrc) => {
    setPreviewSrc(croppedSrc);
    const file = await objectUrlToFile(croppedSrc, fileRef.current.files[0].name);
    writeFilesToInput([file], fileRef.current);

    if (onUpdate) onUpdate(croppedSrc, file);
  };

  return (
    <div className="w-full sm:w-80 mb-8">
      <FormFile.Dropzone
        onUpdate={(file) => {
          setSrc(URL.createObjectURL(file));
          setShowCropModal(true);
        }}
        as={PreviewImage}
        wrapperClassName="mb-0"
        className={twMerge(
          "rounded-3xl bg-content2 hover:bg-default-focus",
          previewSrc && "old-d-none",
        )}
        maxSize={26214400}
        ref={fileRef}
        {...fileProps}
      />

      {previewSrc && (
        <ImageCard
          image={previewSrc}
          overlay={RemovePreviewOverlay}
          overlayProps={{
            onDelete: () => setPreviewSrc(null),
          }}
        />
      )}

      <CropModal
        show={showCropModal}
        onHide={() => {
          setShowCropModal(false);
          fileRef.current.value = "";
        }}
        src={src}
        onSuccess={handleSuccess}
        {...cropProps}
      />
    </div>
  );
}
FormFilePreviewImage.propTypes = {
  defaultValue: PropTypes.string,
  onUpdate: PropTypes.func,
  cropProps: PropTypes.shape({}),
};

export default FormFilePreviewImage;
