import { useMemo, useContext } from "react";
import { UserContext } from "features/user";
import { AuthContext } from "features/authentication";
import { ErrorContext } from "features/report";
import { useNavigate } from "react-router-dom";
import { Drawer, ThemeContext } from "ui";
import useToggleButtons from "hooks/useToggleButtons";
import useScrollAmount from "features/navigation/hooks/useScrollAmount";
import NavigationConfigContext from "features/navigation/context/NavigationConfigContext";
import OffcanvasAccount from "features/navigation/components/navbar/ProspectNavbar/OffcanvasAccount";
import OffcanvasSearch from "features/navigation/components/navbar/ProspectNavbar/OffcanvasSearch";
import NavbarMobile from "features/navigation/components/navbar/ProspectNavbar/NavbarMobile";
import NavbarDesktop from "features/navigation/components/navbar/ProspectNavbar/NavbarDesktop";
import PropTypes from "prop-types";

export default function ProspectNavbar({ icon = undefined, size = undefined }) {
  const [offcanvas, dispatch] = useToggleButtons({
    account: { checked: false },
    search: { checked: false },
    activity: { checked: false }, // Not used yet
  });
  const { user } = useContext(UserContext);
  const { config } = useContext(NavigationConfigContext);
  const { showError } = useContext(ErrorContext);
  const { showAuth } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const scrollReached = useScrollAmount(0.01);
  const navigate = useNavigate();

  const handleToggleOffcanvas = ({ isProtectedAction, page }) => {
    if (isProtectedAction && !user.doesSessionExist) {
      showAuth();
      return;
    }

    dispatch({ id: page, type: "TOGGLE", oneOrTheOther: true });
  };

  const handleToggleCurrentPage = () => {
    const current = Object.keys(offcanvas).find((k) => offcanvas[k].checked);

    if (!current) return;

    handleToggleOffcanvas({ page: current, isProtectedAction: false });
  };

  const handleNavigate = (to) => {
    handleToggleCurrentPage();
    navigate(to, {
      state: {
        referrer: "navbar",
      },
    });
  };

  const navbarConfig = useMemo(() => {
    const routes = config.navbar.routes({
      user,
      handleToggleOffcanvas,
      showError,
      navigate: handleNavigate,
    });

    return {
      size,
      mobileRoutes: routes?.mobileRoutes || [],
      desktopRoutes: routes?.desktopRoutes || [],
      desktopIcons: routes?.desktopIconRoutes || [],
      offcanvas: routes?.offcanvasRoutes || [],
      homeLink: config.redirectHome,
      collapse: config.navbar?.collapse || "md",
      icon: !scrollReached ? icon || config.navbar?.icon[theme] : config.navbar?.icon[theme],
      handleToggleCurrentPage,
    };
  }, [user, JSON.stringify(offcanvas), scrollReached]);

  return (
    <>
      <NavbarDesktop {...navbarConfig} />
      <NavbarMobile {...navbarConfig} />

      <Drawer
        isOpen={offcanvas.account.checked}
        onOpenChange={() => dispatch({ id: "account", type: "UNCHECKED", oneOrTheOther: true })}
        className="mb-16 md:mb-0 h-full relative flex justify-between flex-col py-8 md:py-16 px-8 sm:px-16"
      >
        <OffcanvasAccount {...navbarConfig} />
      </Drawer>

      <Drawer
        isOpen={offcanvas.search.checked}
        onOpenChange={() => dispatch({ id: "search", type: "UNCHECKED", oneOrTheOther: true })}
        className="mb-16 md:mb-0 h-full relative flex justify-between flex-col p-0 pb-8 md:pb-16"
      >
        <OffcanvasSearch {...navbarConfig} />
      </Drawer>
    </>
  );
}
ProspectNavbar.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.string,
};
