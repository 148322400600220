import { useState } from "react";
import { handleSchema } from "utils/validation/validationSchemas";
import { socialPrefixes } from "features/profile/data/socialData";
import { Form, Modal } from "ui";
import PropTypes from "prop-types";
import ValidateForm from "forms/wrappers/ValidationWrappers";
import FormControl from "forms/FormControl";

export default function AddSocialModal({ onHide, onComplete, social, show = false }) {
  const [handle, setHandle] = useState("");

  return (
    <Modal isOpen={show} onOpenChange={onHide} data-testid="add-social-modal">
      <Modal.ModalHeader className="capitalize">Link {social} Account</Modal.ModalHeader>

      <Modal.ModalBody>
        <ValidateForm
          onSubmit={(formData) => {
            onComplete(formData.handle);
            onHide();
          }}
          scrollOnSubmit={false}
          validationSchema={handleSchema}
        >
          {({ handleSubmit, errors, loading }) => (
            <Form onSubmit={handleSubmit} className="old-all-mb-3">
              <div className="old-bg-offwhite old-p-3 old-rounded-4 old-d-flex old-align-items-center">
                <span className="old-text-lightgrey old-fs-2 font-bold old-me-2">@</span>
                <FormControl
                  name="handle"
                  placeholder={`Add ${social} handle here`}
                  className="old-bg-offwhite old-border-offwhite old-w-100 old-rounded-4 old-p-2 old-placeholder-gray"
                  wrapperClassName="old-mb-0 old-w-100 old-text-start"
                  error={errors.handle}
                  onUpdate={setHandle}
                  debounceTime={200}
                />
              </div>

              <span className="old-text-lightgrey old-d-block font-roman">
                {socialPrefixes[social]}
                {handle}
              </span>

              <small className="font-roman">
                Your {social} handle uniquely identifies your {social} account and is typically the
                final part of your {social} profile url, or can be found on your {social} page.
              </small>

              <Modal.ModalActionsFooter
                confirmText="Add Social"
                onCancel={onHide}
                confirmProps={{
                  isLoading: loading,
                }}
              />
            </Form>
          )}
        </ValidateForm>
      </Modal.ModalBody>
    </Modal>
  );
}

AddSocialModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  social: PropTypes.oneOf(["instagram", "tiktok", "facebook", "twitter", "youtube"]).isRequired,
  show: PropTypes.bool,
};
