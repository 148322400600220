import { useState, forwardRef } from "react";
import {
  MDXEditor,
  UndoRedo,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ListsToggle,
  CreateLink,
  linkDialogPlugin,
  linkPlugin,
  toolbarPlugin,
  listsPlugin,
  headingsPlugin,
} from "@mdxeditor/editor";
import { Form } from "ui";
import useDebounce from "hooks/useDebounce";
import PropTypes from "prop-types";
import FormWrapper, { getWrapperProps } from "forms/wrappers/FormWrapper";

function ToolbarComponents() {
  return (
    <>
      <UndoRedo />
      <BoldItalicUnderlineToggles />
      <CreateLink />
      <ListsToggle />
      <BlockTypeSelect />
    </>
  );
}

const FormRichMedia = forwardRef(
  ({ onUpdate, defaultValue, debounceTime, className, mdxClassName, ...props }, ref) => {
    const [state, setState] = useState(defaultValue);
    const [wrapperProps, { name, maxLength, ...inputProps }] = getWrapperProps(props);

    useDebounce(
      () => {
        if (onUpdate) onUpdate(state);
      },
      [state],
      debounceTime,
    );

    return (
      <FormWrapper {...wrapperProps}>
        <div data-role="form-error-component" className={className}>
          <MDXEditor
            markdown={defaultValue}
            plugins={[
              toolbarPlugin({
                toolbarContents: ToolbarComponents,
              }),
              linkPlugin(),
              linkDialogPlugin(),
              listsPlugin(),
              headingsPlugin(),
            ]}
            onChange={setState}
            className={mdxClassName}
            contentEditableclassName={`${name}-content-editable`}
            ref={ref}
            {...inputProps}
          />
        </div>

        {/* This lets us grab the value on submit */}
        <Form.Control as="textarea" value={state} name={name} readOnly hidden />

        {maxLength && (
          <small className="old-position-absolute old-end-2 old-pe-2 old-bottom-2 font-bold">
            {state.length} / {maxLength}
          </small>
        )}
      </FormWrapper>
    );
  },
);

FormRichMedia.propTypes = {
  onUpdate: PropTypes.func,
  defaultValue: PropTypes.string,
  debounceTime: PropTypes.number,
  className: PropTypes.string,
  mdxClassName: PropTypes.string,
};

FormRichMedia.defaultProps = {
  onUpdate: undefined,
  defaultValue: "",
  debounceTime: 800,
  className: "old-bg-offprimary old-border-offprimary old-border old-rounded-4 old-overflow-hidden",
  mdxClassName: "",
};

export default FormRichMedia;
