import { useParams } from "react-router-dom";
import { Container, CallToAction, DateProgressBar } from "ui";
import routes from "default/routes";
import { PageLayout } from "features/navigation";
import { useBrief } from "features/brief";

export default function EnterBriefComplete() {
  const { id } = useParams();
  const { data, isLoading } = useBrief(id);
  const brief = data || {};

  return (
    <PageLayout isLoading={isLoading}>
      <Container className="old-mb-6" data-testid="published-submission">
        <div className="text-center old-mb-5">
          <h2 className="uppercase old-mt-5 old-mb-5">Well Done! ✨</h2>

          <div className="old-d-flex old-w-100 old-justify-content-around old-py-md-4 old-py-3 old-flex-wrap old-flex-sm-nowrap">
            <DateProgressBar
              startDate={brief.start_time}
              endDate={brief.submission_deadline}
              title="Submit Your Work"
            />
            <DateProgressBar
              startDate={brief.submission_deadline}
              endDate={brief.voting_deadline}
              title="Voting"
            />
            <DateProgressBar
              startDate={brief.voting_deadline}
              endDate={brief.winners_selected}
              title="Winner Announced"
            />
          </div>
        </div>

        <Container className="old-bg-offdark old-text-white old-rounded-4 old-mb-3 old-py-3 old-py-md-6">
          <CallToAction
            title={
              <>
                Share your
                <br />
                opinion
              </>
            }
            description="Feedback is a way to help out your fellow designers. Check the Feedback Hub often to see who has given you feedback on your submission, or to give feedback to a fellow designer!"
            cta="Explore Feedback"
            to={routes.feedbackHub}
            variant="white"
            trackingName="view feedback"
          />
        </Container>
      </Container>
    </PageLayout>
  );
}
