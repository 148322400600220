import { useContext, useMemo } from "react";
import { Link, generatePath, useNavigate } from "react-router-dom";
import routes from "default/routes";
import {
  SubmissionCard,
  submissionStatusContent,
  proposalStatusContent,
  useSubmissions,
} from "features/submission";
import { briefPropTypes, BriefTimeline, briefStatusContent } from "features/brief";
import { UserContext } from "features/user";
import { Container, Row, Col, CardGrid, AutoScrollLoader, Button } from "ui";
import PropTypes from "prop-types";

function SubmissionStatus({ text, icon, ctaText = "", ctaPath = "" }) {
  const Icon = icon;

  return (
    <div className="old-d-flex old-flex-column old-align-items-center old-justify-content-center old-flex-grow-1 text-center old-p-6 old-all-mb-3 old-text-black">
      <Icon className="!size-24 old-text-lightgrey" />
      <p>{text}</p>

      {ctaText && ctaPath && (
        <Button color="primary" as={Link} to={ctaPath}>
          {ctaText}
        </Button>
      )}
    </div>
  );
}
SubmissionStatus.propTypes = {
  text: PropTypes.node.isRequired,
  icon: PropTypes.elementType.isRequired,
  ctaText: PropTypes.node,
  ctaPath: PropTypes.string,
};

export default function BriefWorkspace({ brief }) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const submissions = useSubmissions({
    user_id: user.username,
    brief_id: brief.id,
    type: ["submission"],
    sort: "newest",
  });
  const proposals = useSubmissions({
    user_id: user.username,
    brief_id: brief.id,
    type: ["proposal"],
    sort: "newest",
  });

  const submissionStatus = useMemo(() => {
    if (brief.type === "private") {
      if (proposals.all.length <= 0 || ["proposing", "selecting"].includes(brief.status))
        return "noProposal";
      if (!proposals.all[0].is_winner) return "locked";
    }
    if (submissions.all.length <= 0 && ["voting", "judging"].includes(brief.status))
      return "noSubmission";
    return brief.status;
  }, [proposals.all, submissions.all]);

  const { callToAction } = briefStatusContent(brief)[brief.status];
  const { icon: submissionIcon, text: submissionText } =
    submissionStatusContent()[submissionStatus];
  const { icon: proposalIcon, text: proposalText } =
    proposalStatusContent()[brief.status !== "proposing" ? "noProposal" : brief.status];

  return (
    <div
      className="old-bg-white old-rounded-top-4 old-position-relative old-sb-6"
      data-testid="brief-workspace"
    >
      <Container className="old-contain-paint old-py-6 old-mt-n6 old-position-relative old-bg-white old-rounded-top-4">
        <Row gap={6}>
          {brief.type === "private" && (
            <Col xs={12} md={6}>
              <div className="old-d-flex old-flex-column border-none old-border-top old-border-2 old-border-lightgrey old-h-100">
                <span className="uppercase old-text-lightgrey font-bold old-mb-3 old-d-block old-mt-4">
                  Your Proposals
                </span>

                <CardGrid
                  entries={proposals.all}
                  card={SubmissionCard}
                  sizing={{ xs: 12, xxl: 6 }}
                  onClick={(submission) =>
                    navigate(
                      generatePath(routes.briefSubmission, {
                        id: brief.id,
                        submissionId: submission.id,
                      }),
                      {
                        state: {
                          scrollToTop: false,
                        },
                      },
                    )
                  }
                />

                <AutoScrollLoader
                  fetchNextPage={proposals.fetchNextPage}
                  hasNextPage={proposals.hasNextPage}
                  isLoading={proposals.isLoading || proposals.isFetchingNextPage}
                  error={proposals.error}
                  empty={!proposals.all.length}
                  errorButtonVariant="black"
                  textClassName=""
                >
                  <SubmissionStatus
                    icon={proposalIcon}
                    text={proposalText}
                    ctaText={callToAction?.text}
                    ctaPath={callToAction?.path}
                  />
                </AutoScrollLoader>
              </div>
            </Col>
          )}

          <Col xs={12} md={brief.type === "private" ? 6 : 12}>
            <div className="old-d-flex old-flex-column border-none old-border-top old-border-2 old-border-lightgrey old-h-100">
              <span className="uppercase old-text-lightgrey font-bold old-mb-3 old-d-block old-mt-4">
                Your Submissions
              </span>

              <CardGrid
                entries={submissions.all}
                card={SubmissionCard}
                sizing={{
                  ...(brief.type === "public" && { md: 6, xl: 4 }),
                  ...(brief.type === "private" && { xs: 12, xxl: 6 }),
                }}
                onClick={(submission) =>
                  navigate(
                    generatePath(routes.briefSubmission, {
                      id: brief.id,
                      submissionId: submission.id,
                    }),
                    {
                      state: {
                        scrollToTop: false,
                      },
                    },
                  )
                }
              />

              <AutoScrollLoader
                fetchNextPage={submissions.fetchNextPage}
                hasNextPage={submissions.hasNextPage}
                isLoading={submissions.isLoading || submissions.isFetchingNextPage}
                error={submissions.error}
                empty={!submissions.all.length}
                errorButtonVariant="black"
                textClassName=""
              >
                <SubmissionStatus
                  icon={submissionIcon}
                  text={submissionText}
                  ctaText={callToAction?.text}
                  ctaPath={callToAction?.path}
                />
              </AutoScrollLoader>
            </div>
          </Col>
        </Row>
        {brief.status !== "completed" && (
          <div className="old-rounded-4 old-bg-dark old-text-white old-mt-6 old-py-4">
            <Container className="old-d-flex old-justify-content-around">
              <BriefTimeline brief={brief} />
            </Container>
          </div>
        )}
      </Container>
    </div>
  );
}
BriefWorkspace.propTypes = {
  brief: briefPropTypes.isRequired,
};
