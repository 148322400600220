import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import ForumIcon from "@mui/icons-material/Forum";
import PropTypes from "prop-types";

import { NavigationConfigContext } from "features/navigation";
import { generatePath } from "utils/helpers";
import { StickyActionButton, StickyActionShareButton } from "ui";
import routes from "default/routes";

import submissionPropTypes from "../../../schemas/submissionSchema";
import SubmissionStickyActionVoteButton from "../../button/SubmissionStickyActionVoteButton";

const trackingLocation = "submission actions";

export default function SubmissionActions({
  submission,
  handleVote,
  scrollToFeedback,
  allowSelectWinner = false,
  showVoteButton = true,
}) {
  const { config } = useContext(NavigationConfigContext);
  const navigate = useNavigate();

  return (
    <div className="w-14 -mr-14 hidden md:block sticky top-32 h-full text-primary-foreground">
      <div className="ml-4 ">
        {showVoteButton && (
          <SubmissionStickyActionVoteButton
            handleVote={handleVote}
            hasVoted={submission.voted}
            numberVotes={submission.votes}
            trackingName="vote"
            trackingLocation={trackingLocation}
            allowSelectWinner={allowSelectWinner}
            color="success"
          />
        )}

        <StickyActionButton
          onClick={scrollToFeedback}
          title="Feedback"
          tooltip="Join the conversation!"
          icon={ForumIcon}
          trackingName="view feedback"
          trackingLocation={trackingLocation}
          variant="faded"
          className="border-none p-1"
        />

        <StickyActionButton
          onClick={() =>
            navigate(
              `${generatePath(config.redirectDefault, { wildcard: generatePath(routes.brief, { id: submission.brief.id }) })}?tab=details`,
            )
          }
          title="View Brief"
          tooltip={submission.brief.title}
          icon={FeedOutlinedIcon}
          trackingName="view brief"
          trackingLocation={trackingLocation}
          variant="faded"
          className="border-none"
        />

        <StickyActionShareButton
          title={`${submission.title} - entry to ${submission.brief.title} - by ${submission.user.username}`}
          url={window.location.pathname}
          trackingLocation={trackingLocation}
          variant="faded"
          className="border-none"
          shareButtonProps={{
            color: "primary",
          }}
        />
      </div>
    </div>
  );
}
SubmissionActions.propTypes = {
  submission: submissionPropTypes.isRequired,
  handleVote: PropTypes.func.isRequired,
  scrollToFeedback: PropTypes.func.isRequired,
  allowSelectWinner: PropTypes.bool,
  showVoteButton: PropTypes.bool,
};
