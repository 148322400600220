import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import VerifiedBadge from "icons/VerifiedBadge";
import { Tabs, Tab } from "ui";
import { useBriefs } from "features/brief";
import prospectImage from "images/prospectImage.webp";

import BriefFilter from "./BriefFilter";

const allBrief = {
  organisation: { name: "All" },
  thumbnail: prospectImage,
  id: 0, // An id that won't exist in the backend
};
const DEFAULT_BRIEF = allBrief.id;
const DEFAULT_USER_TYPE = "all";

export default function Filters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { all } = useBriefs({ status: ["voting", "judging"] });
  const briefs = useMemo(() => (all ? [allBrief, ...all] : []), [all]);

  return (
    <div className="flex flex-col-reverse md:flex-row justify-center position-relative gap-5 mb-4">
      <Tabs
        size="lg"
        variant="underlined"
        color="primary"
        selectedKey={searchParams.get("t") ?? DEFAULT_USER_TYPE}
        onSelectionChange={(userTypeId) => {
          setSearchParams(
            (prev) => {
              if (userTypeId === DEFAULT_USER_TYPE) prev.delete("t");
              else prev.set("t", userTypeId);
              return prev;
            },
            { replace: true },
          );
        }}
        classNames={{
          tab: "h-auto",
          tabList: "gap-0",
          base: "h-full flex items-center justify-center",
        }}
      >
        <Tab
          key="all"
          title={
            <div className="flex flex-col items-center space-x-1 sm:space-y-1">
              <StarIcon className="!size-5" />
              <span className="text-nowrap pb-2 px-4 text-md capitalize">Featured</span>
            </div>
          }
        />
        <Tab
          key="veteran"
          title={
            <div className="flex flex-col items-center space-x-1 sm:space-y-1">
              <VerifiedBadge className="!size-5 group-data-[selected=true]:text-secondary" />
              <span className="text-nowrap pb-2 px-4 text-md capitalize">Veterans</span>
            </div>
          }
        />
      </Tabs>

      {briefs.length > 1 && !searchParams.get("search") && (
        <BriefFilter
          briefs={briefs}
          handleSelectBrief={(briefId) => {
            setSearchParams(
              (prev) => {
                if (Number(briefId) === DEFAULT_BRIEF) prev.delete("brief");
                else prev.set("brief", briefId);
                return prev;
              },
              { replace: true },
            );
          }}
          defaultSelectedId={Number(DEFAULT_BRIEF)}
        />
      )}
    </div>
  );
}
