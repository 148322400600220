import { VisuallyHidden, useSwitch } from "@nextui-org/react";
import LanguageIcon from "@mui/icons-material/Language";

export default function CountrySwitch(props) {
  const { Component, slots, getBaseProps, getInputProps, getWrapperProps } = useSwitch(props);

  return (
    <div className="flex flex-col gap-2 bg-default-100 p-1 rounded-xl">
      <Component {...getBaseProps()}>
        <VisuallyHidden>
          <input {...getInputProps()} />
        </VisuallyHidden>
        <div
          {...getWrapperProps()}
          className={slots.wrapper({
            class: [
              "w-9 h-9 md:w-12 md:h-10",
              "flex items-center justify-center",
              "rounded-xl bg-default-100 m-0 p-0",
            ],
          })}
        >
          <LanguageIcon className="!size-6 md:!size-8" />
        </div>
      </Component>
    </div>
  );
}
