import { useState, forwardRef, useCallback, useEffect } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { Form, Button } from "ui";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";
import FormWrapper, { getWrapperProps } from "forms/wrappers/FormWrapper";
import FormSelect from "forms/FormSelect";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Get a list of all years between two dates
const getYears = (startDate, endDate) => {
  const currentYear = endDate.getFullYear();
  const years = [];
  let startYear = startDate.getFullYear();
  while (startYear <= currentYear) {
    years.push(startYear);
    startYear += 1;
  }

  return years.reverse();
};

const CustomInput = forwardRef(({ className, value, onClick, ...props }, ref) => (
  <div className="old-d-flex old-align-items-center old-position-relative">
    <Form.Control
      data-role="form-error-component"
      onClick={onClick}
      ref={ref}
      value={value}
      readOnly
      className={className}
      {...props}
    />

    <CalendarMonthIcon onClick={onClick} className="old-position-absolute old-end-5" />
  </div>
));
CustomInput.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
};
CustomInput.defaultProps = {
  className: "",
  value: "",
  onClick: undefined,
};

function CustomContainer({ className, children }) {
  return (
    <CalendarContainer className={`old-rounded-4 old-shadow old-border-0 old-p-2 ${className}`}>
      <div className="old-d-flex" data-testid="datepicker">
        {children}
      </div>
    </CalendarContainer>
  );
}
CustomContainer.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const FormDateTime = forwardRef(
  (
    { onUpdate, defaultValue, dateFormat, timeFormat, minDate, maxDate, className, ...props },
    ref,
  ) => {
    const [state, setState] = useState(defaultValue ? new Date(defaultValue) : "");
    const [wrapperProps, { name, disabled, ...inputProps }] = getWrapperProps(props);

    useEffect(() => {
      if (onUpdate) onUpdate(state);
    }, [state]);

    const CustomHeader = useCallback(
      ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="old-d-flex old-justify-content-center old-align-items-center old-mb-2">
          <FormSelect
            defaultValue={months[dayjs(date).month()]}
            onUpdate={(month) => changeMonth(months.indexOf(month))}
            wrapperClassName="old-mb-0"
            className="old-bg-transparent old-border-0 old-me-2"
            aria-label="datepicker-month"
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </FormSelect>

          <FormSelect
            defaultValue={dayjs(date).year()}
            onUpdate={changeYear}
            wrapperClassName="old-mb-0"
            className="old-bg-transparent old-border-0 old-me-2"
            aria-label="datepicker-year"
          >
            {getYears(minDate, maxDate).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </FormSelect>

          <Button
            variant="light"
            isIconOnly
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            <KeyboardArrowLeftIcon />
          </Button>

          <Button
            variant="light"
            isIconOnly
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </div>
      ),
      [minDate, maxDate],
    );

    return (
      <FormWrapper {...wrapperProps}>
        <DatePicker
          selected={state}
          onChange={(date) => setState(date)}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          peekNextMonth
          placeholderText={[dateFormat ? "dd/mm/yyyy" : "", timeFormat ? "hh:mm" : ""]
            .filter(Boolean)
            .join(" ")}
          dateFormat={[dateFormat ? "dd/MM/yyy" : "", timeFormat ? "HH:mm" : ""]
            .filter(Boolean)
            .join(" ")}
          timeFormat="HH:mm"
          wrapperClassName="old-w-100"
          customInput={<CustomInput {...inputProps} />}
          calendarContainer={CustomContainer}
          renderCustomHeader={CustomHeader}
          maxDate={maxDate}
          minDate={minDate}
          showTimeSelect={timeFormat}
          showTimeSelectOnly={timeFormat && !dateFormat}
          timeIntervals={5}
          className={className}
          disabled={disabled}
        />

        {/* We display the value in a seperate hidden input so it is always displayed in the correct format,
            this way, the picker can be any format without messing with form validation */}
        <Form.Control
          hidden
          ref={ref}
          value={
            state
              ? dayjs(state).format(
                  [dateFormat ? "YYYY-MM-DD" : "", timeFormat ? "HH:mm" : ""]
                    .filter(Boolean)
                    .join(" "),
                )
              : ""
          }
          name={name}
          readOnly
        />
      </FormWrapper>
    );
  },
);
FormDateTime.propTypes = {
  onUpdate: PropTypes.func,
  defaultValue: PropTypes.string,
  dateFormat: PropTypes.bool,
  timeFormat: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  className: PropTypes.string,
};
FormDateTime.defaultProps = {
  onUpdate: undefined,
  defaultValue: "",
  dateFormat: true,
  timeFormat: true,
  minDate: new Date(1920, 0, 1),
  maxDate: new Date(),
  className:
    "!bg-content2 !border-content2 !placeholder-primary-300 !text-foreground old-rounded-4 old-p-3 old-d-inline-block",
};

export default FormDateTime;
