import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

const MAXCOLS = 12;
const BREAKPOINTS = [
  ["xs", ""],
  ["sm", "sm"],
  ["md", "md"],
  ["lg", "lg"],
  ["xl", "xl"],
  ["xxl", "2xl"],
  ["xxxl", "3xl"],
];

export default function Col({ className = "", as = "div", children, ...props }) {
  const As = as;
  let prev = BREAKPOINTS[0][0];
  const baseClass = BREAKPOINTS.some(([bp]) => Object.keys(props).includes(bp))
    ? BREAKPOINTS.map(([bp, k]) => {
        if (props[bp]) prev = bp;

        const breakpoint = k ? `${k}:` : "";
        return `${breakpoint}${!props[prev] || props[prev] === 12 ? "w-full" : `w-${props[prev]}/${MAXCOLS}`}`;
      })
    : ["flex-1"];

  return (
    <As className={twMerge(baseClass.join(" "), className)} {...props}>
      {children}
    </As>
  );
}
Col.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  as: PropTypes.elementType,
};
