import dayjs from "dayjs";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const privateBriefNDA = (brief, user) => {
  const now = dayjs(Date.now()).format("DD/MM/YYYY");
  const theUser =
    user?.given_name && user?.family_name
      ? `${capitalizeFirstLetter(user.given_name)} ${capitalizeFirstLetter(user.family_name)}`
      : "The User";

  return `Standard Non-disclosure Agreement

This Nondisclosure Agreement (the "Agreement") is entered into by and between ${brief.organisation.name} ("Disclosing Party") on ${now} and ${theUser} ("Receiving Party") on ${now} for the purpose of preventing the unauthorized disclosure of Confidential Information as defined below. The parties agree to enter into a confidential relationship with respect to the disclosure of certain proprietary and confidential information ("Confidential Information").

##### **1. Definition of Confidential Information**

For purposes of this Agreement, "Confidential Information" shall include all information or material that has or could have commercial value or other utility in the business in which Disclosing Party is engaged.

##### **2. Exclusions from Confidential Information**

Receiving Party's obligations under this Agreement do not extend to information that is:

- publicly known at the time of disclosure or subsequently becomes publicly known through no fault of the Receiving Party;
- discovered or created by the Receiving Party before disclosure by Disclosing Party;
- learned by the Receiving Party through legitimate means other than from the Disclosing Party or Disclosing Party's representatives; or
- is disclosed by Receiving Party with Disclosing Party's prior written approval

##### **3. Obligations of Receiving Party**

Receiving Party shall hold and maintain the Confidential Information in strictest confidence for the sole and exclusive benefit of the Disclosing Party. Receiving Party shall not, without prior written approval of Disclosing Party, use for Receiving Party's own benefit, publish, copy, or otherwise disclose to others, or permit the use by others for their benefit or to the detriment of Disclosing Party, any Confidential Information.

##### **4. Time Periods**

The nondisclosure provisions of this Agreement shall survive the termination of this Agreement and Receiving Party's duty to hold Confidential Information in confidence shall remain in effect until the Confidential Information no longer qualifies as a trade secret or until Disclosing Party sends Receiving Party written notice releasing Receiving Party from this Agreement, whichever occurs first.

##### **5. Relationships**

Nothing contained in this Agreement shall be deemed to constitute either party a partner, joint venturer or employee of the other party for any purpose.

##### **6. Severability**

If a court finds any provision of this Agreement invalid or unenforceable, the remainder of this Agreement shall be interpreted so as best to effect the intent of the parties.

##### **7. Integration**

This Agreement expresses the complete understanding of the parties with respect to the subject matter and supersedes all prior proposals, agreements, representations, and understandings. This Agreement may not be amended except in a writing signed by both parties.

##### **8. Waiver**

The failure to exercise any right provided in this Agreement shall not be a waiver of prior or subsequent rights.

This Agreement and each party's obligations shall be binding on the representatives, assigns, and successors of such party. Each party has signed this Agreement through its authorized representative.
    `;
};

export { privateBriefNDA };
