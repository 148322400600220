import LockIcon from "@mui/icons-material/Lock";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import AddIcon from "@mui/icons-material/Add";

const proposalStatusContent = () => ({
  proposing: {
    icon: AddIcon,
    text: "This brief is now accepting proposals",
  },
  noProposal: {
    icon: ImageNotSupportedOutlinedIcon,
    text: (
      <>
        This brief has passed the proposal deadline.
        <br />
        Make sure to enter the next one!
      </>
    ),
  },
});

const submissionStatusContent = () => ({
  noProposal: {
    icon: LockIcon,
    text: "An accepted proposal is needed to create a final submission.",
  },
  noSubmission: {
    icon: ImageNotSupportedOutlinedIcon,
    text: (
      <>
        This brief has passed the submission deadline.
        <br />
        Make sure to enter the next one!
      </>
    ),
  },
  locked: {
    icon: LockIcon,
    text: (
      <>
        Unfortunately your proposal was not approved.
        <br />
        Thank you for your participation.
      </>
    ),
  },
  submitting: {
    icon: AddIcon,
    text: "This brief is now accepting submissions",
  },
  /* add tests for voting and judging */
  voting: {
    icon: LockIcon,
    text: "This brief is now in the voting phase!",
  },
  judging: {
    icon: LockIcon,
    text: "This brief is now in the judging phase!",
  },
  completed: {
    icon: AddIcon,
    text: (
      <>
        This brief is now completed!
        <br />
        However, you may still make submissions for your own enjoyment or portfolio.
      </>
    ),
  },
});

export { submissionStatusContent, proposalStatusContent };
