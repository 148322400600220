import { useEffect, useState } from "react";
import api from "adapters/api";

const useTags = ({ group = "design_interest", fetchUrl = "tags/", selectedUrl = "" }) => {
  const [tags, setTags] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTags = async () => {
      const [tagsRes, selectedRes] = await Promise.all([
        api.get({ url: fetchUrl, params: { group } }),
        ...(selectedUrl ? [api.get({ url: selectedUrl })] : []),
      ]);

      if (!tagsRes.success || !(selectedRes?.success || true)) {
        setLoading(false);
        return;
      }

      const selected = selectedRes?.data || [];
      const newTags = tagsRes.data.map((tag) => ({
        [tag.value]: {
          label: tag.value.replace("_", " "),
          checked: selected.some((st) => st.id === tag.id),
        },
      }));

      setTags(Object.assign({}, ...newTags));
      setLoading(false);
    };
    getTags();
  }, [selectedUrl]);

  return {
    tags,
    loading,
  };
};

export default useTags;
