import { useQuery } from "@tanstack/react-query";
import { client } from "api/client";

const fetchOne = async ({ queryKey: [{ url, id, params }], signal }) => {
  if (id == null) return {};
  const response = await client.get(url + id, {
    signal,
    params,
  });
  return response.data;
};

const useOne = (queryOptions) =>
  useQuery({
    ...queryOptions,
    queryFn: fetchOne,
  });

export default useOne;
