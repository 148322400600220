import { useMemo } from "react";
import Progress from "ui/status/Progress";
import dayjs from "dayjs";
import PropTypes from "prop-types";

export default function DateProgressBar({
  startDate,
  endDate,
  title = "",
  textClassName = "old-text-lightgrey",
}) {
  const progress = useMemo(() => {
    if (Date.now() >= dayjs(endDate)) return 100;

    if (Date.now() <= dayjs(startDate)) return 0;

    const length = dayjs(endDate) - dayjs(startDate);
    const current = Date.now() - dayjs(startDate);
    return (current / length) * 100;
  }, [startDate, endDate]);

  const visible = useMemo(
    () => Date.now() > dayjs(startDate) && Date.now() < dayjs(endDate),
    [startDate],
  );

  return (
    <div
      className={`old-d-${visible ? "block" : "none"} ${textClassName} old-d-md-block uppercase text-center old-w-100 old-all-mb-3 old-px-2`}
    >
      {title !== "" && <h4 className="old-d-block">{title}</h4>}

      <div className="old-bg-white old-p-2 old-rounded-pill">
        <Progress value={progress} />
      </div>

      <h4>
        {dayjs(startDate).format("MMM D")} - {dayjs(endDate).format("MMM D")}
      </h4>
    </div>
  );
}

DateProgressBar.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  title: PropTypes.string,
  textClassName: PropTypes.string,
};
