import { useRef } from "react";
import { twJoin } from "tailwind-merge";
import PropTypes from "prop-types";

function Collapse({ isOpen = false, children }) {
  const contentRef = useRef(null);

  return (
    <div
      ref={contentRef}
      className={twJoin(
        "transition-all duration-300 ease-in-out overflow-hidden",
        isOpen ? "max-h-screen" : "max-h-0 invisible",
      )}
      style={{
        maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : "0px",
      }}
    >
      {children}
    </div>
  );
}
Collapse.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Collapse;
