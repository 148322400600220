const stripLeadingUS = (input) => {
  const lowerCaseInput = input.toLowerCase();
  if (lowerCaseInput.startsWith("us")) return input.substring(2);

  return input;
};

class Currency {
  static registry = {};

  // Add more currencies here
  static GBP = new Currency("GBP", "British Pound", "£");

  static USD = new Currency("USD", "United States Dollar", "$");

  constructor(code, name, symbol) {
    this.code = code;
    this.name = name;
    this.symbol = symbol;
    this.formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: code,
      maximumFractionDigits: 0,
    });

    Currency.registry[code] = this;
  }

  static values = Object.values(Currency.registry);

  static get(code) {
    return Currency.registry[code];
  }

  toString() {
    return `${this.name} (${this.symbol})`;
  }

  format(number) {
    return stripLeadingUS(this.formatter.format(number));
  }
}

export default Currency;
