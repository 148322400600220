import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { supertokensStyles, supertokensTranslations } from "features/authentication";
import { ThirdPartyPasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartypasswordless/prebuiltui";
import SuperTokens from "supertokens-auth-react";
import ThirdPartyPasswordless from "supertokens-auth-react/recipe/thirdpartypasswordless";
import Session from "supertokens-auth-react/recipe/session";
import Mixpanel from "adapters/mixpanel";
import * as reactRouterDom from "react-router-dom";

const init = ({ overrideAppInfo, overrideAuthentication }) => {
  SuperTokens.init({
    languageTranslations: {
      translations: supertokensTranslations,
      defaultLanguage: "en",
    },
    appInfo: {
      appName: "PROSPECT 100",
      apiDomain: process.env.REACT_APP_API_SERVER_URL,
      websiteDomain: process.env.REACT_APP_DEFAULT_URL,
      apiBasePath: "/api/v1/auth",
      websiteBasePath: "/auth",
      ...overrideAppInfo,
    },
    recipeList: [
      Session.init({
        tokenTransferMethod: "header",
      }),
      ThirdPartyPasswordless.init({
        style: supertokensStyles,
        contactMethod: "EMAIL",
        signInUpFeature: {
          disableDefaultUI: true,
          providers: [ThirdPartyPasswordless.Google.init()],
        },
        ...overrideAuthentication,
      }),
    ],
  });
};

// Inject tracking into superTokens routes
const getSuperTokensRoutes = () => {
  const superTokensRoutes = [
    ...getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
      ThirdPartyPasswordlessPreBuiltUI,
    ]).map((r) => r.props),
  ];

  return superTokensRoutes.map((route) => ({
    ...route,
    loader: () => {
      Mixpanel.track_pageview();
      return null;
    },
  }));
};

export default {
  init,
  getSuperTokensRoutes,
};
