import { useState, useContext, useEffect } from "react";
import { briefNotInterestedSchema } from "utils/validation/validationSchemas";
import { Form, Modal } from "ui";
import { useQueryClient } from "api";
import { briefQueries, briefPropTypes } from "features/brief";
import { UserContext } from "features/user";
import api from "adapters/api";
import PropTypes from "prop-types";
import ValidateForm from "forms/wrappers/ValidationWrappers";

const reasons = {
  length: "I didn't have enough time to complete the brief",
  accidental: "I registered by accident",
  description: "The brief instructions weren't clear enough",
  incentives: "I lost interest and motivation",
};

export default function BriefNotInterestedModal({ onHide, brief, show = false }) {
  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();
  const [reason, setReason] = useState("");

  const handleNotInterested = async (formData) => {
    const res = await api.put({
      url: `briefs/${brief.id}/enter/`,
      data: {
        not_interested: reason,
      },
    });

    if (res.success) {
      queryClient.invalidateQueries({
        queryKey: briefQueries.lists({ username: user.username }),
      });
      onHide();
      return {};
    }

    return {
      errors: {
        ...res.parseError(formData),
        api: res.errorDetails,
      },
    };
  };

  useEffect(() => setReason(""), [show]);

  return (
    <Modal isOpen={show} onOpenChange={onHide} data-testid="brief-not-interested-modal">
      <Modal.ModalHeader>No Longer Interested In This Brief?</Modal.ModalHeader>

      <ValidateForm
        onSubmit={handleNotInterested}
        validationSchema={briefNotInterestedSchema}
        scrollOnSubmit={false}
        errorPage="other"
      >
        {({ handleSubmit, errors, loading }) => (
          <Form onSubmit={handleSubmit} className="old-bg-white">
            <Modal.ModalBody className="gap-0 font-roman">
              <small className="mb-3">
                Let us know why to help us improve our briefs in the future!
              </small>

              {Object.keys(reasons).map((curReason) => (
                <Form.Check type="radio" id={curReason} key={curReason} className="old-mb-2">
                  <Form.Check.Input
                    type="radio"
                    name="reason"
                    onChange={() => setReason(curReason)}
                  />
                  <Form.Check.Label className="font-roman old-fs-14">
                    {reasons[curReason]}
                  </Form.Check.Label>
                </Form.Check>
              ))}

              {errors.reason && <small className="old-text-danger">{errors.reason}</small>}
            </Modal.ModalBody>

            <Modal.ModalActionsFooter
              onCancel={onHide}
              confirmProps={{
                isDisabled: !reason || loading,
                isLoading: loading,
              }}
            />
          </Form>
        )}
      </ValidateForm>
    </Modal>
  );
}

BriefNotInterestedModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  brief: briefPropTypes.isRequired,
  show: PropTypes.bool,
};
