import { useReducer } from "react";
import { readFile, formatBytes } from "utils/helpers";
import api, { GCSApi } from "adapters/api";

const DEFAULTERROR = "Something went wrong, please try again.";

const reducer = (state, action) => {
  let files = [...state];

  switch (action.type) {
    case "ADD":
      files = [...files, action.payload];
      break;
    case "REORDER":
      files.splice(action.payload.nextIndex, 0, files.splice(action.payload.prevIndex, 1)[0]);
      break;
    case "REMOVE": {
      const ids = Array.isArray(action.payload) ? action.payload : [action.payload];
      files = files.filter((f) => !ids.includes(f.file_id));
      break;
    }
    case "UPDATE":
      files[action.payload.index] = {
        ...files[action.payload.index],
        ...action.payload.update,
      };
      break;
    default:
      files = action.payload;
      break;
  }

  // Set the corrcet order id
  return files.length ? files.map((f, i) => ({ ...f, order_id: i })) : files;
};

// Format the files object
export const deserializeFileData = (files) =>
  files.map(({ id, ...file }) => ({
    file_id: file.file.id,
    name: file.file.name,
    url: file.file.url,
    ...file,
  }));

export const deserializeImageData = (images) =>
  images.map(({ order_id: orderId, ...image }) => {
    const { url, name } = image.image.compressed || image.image.original;

    return {
      order_id: orderId,
      url,
      name,
      file_id: image.image.id,
      type: "image",
    };
  });

export const serializeExistingFileData = (files) =>
  files.map(({ file, url, selected, chosen, isNewFile, name, ...keep }) => keep);

export const serializeNewFileData = (files) =>
  files.map(({ file, url, selected, chosen, isNewFile, ...keep }) => keep);

export default function useGcsFileUpload(forFileType, maxSize, defaultValue = []) {
  const [files, dispatch] = useReducer(reducer, defaultValue);
  let controller = new AbortController();

  const onUploadProgress = (progressEvent, setProgress) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

    if (percentCompleted > 0 && percentCompleted < 100) setProgress(percentCompleted);
  };

  const addNewFile = async (file, fileReference) => {
    dispatch({
      type: "ADD",
      payload: {
        file_id: fileReference.data.id,
        type: forFileType,
        name: file.name,
        description: file.name, // unless changed, the description is the name of the file.
        url: await readFile(file),
        file,
        isNewFile: true,
      },
    });
  };

  const handleFileUpload = async (file, setProgress) => {
    if (maxSize && file.size > maxSize) {
      alert(`The selected file exceeds the ${formatBytes(maxSize)} filesize limit.`);
      return `The selected file exceeds the ${formatBytes(maxSize)} filesize limit.`;
    }

    setProgress(1);

    const fileReference = await api.put({ url: "users/files" });

    if (!fileReference.success) {
      setProgress(0);
      return fileReference.errorDetails.message || DEFAULTERROR;
    }

    const uploadToGCS = await GCSApi.put({
      url: fileReference.data.upload_url,
      data: file,
      signal: controller.signal,
      onUploadProgress: (progressEvent) => onUploadProgress(progressEvent, setProgress),
    });

    setProgress(0);

    // If they cancel, return with no error
    if (uploadToGCS?.canceled) return "";

    if (!uploadToGCS.success) return uploadToGCS.message || DEFAULTERROR;

    addNewFile(file, fileReference);
    return "";
  };

  const handleCancelFileUpload = () => {
    controller.abort();

    // Create a new instance so we can upload again
    controller = new AbortController();
  };

  return {
    files,
    dispatch,
    handleFileUpload,
    handleCancelFileUpload,
  };
}
