function SvgLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-10 -10 45 45"
      width={25.636}
      height={26.199}
      fill="currentColor"
      {...props}
    >
      <path d="M15.257 11.094 24.8 0h-2.261l-8.287 9.632L7.634 0H0l10.009 14.566L0 26.2h2.262l8.751-10.172L18 26.2h7.634L15.256 11.094Zm-3.1 3.6-1.014-1.45L3.077 1.7H6.55l6.511 9.314 1.014 1.45 8.465 12.11h-3.474l-6.907-9.879Z" />
    </svg>
  );
}
export default SvgLogo;
