import { useState, forwardRef, useEffect } from "react";
import { Form } from "ui";
import PropTypes from "prop-types";
import FormWrapper, { getWrapperProps } from "forms/wrappers/FormWrapper";
import BootstrapSelect from "forms/bootstrapSelect/Bootstrap-select";

const FormSelect = forwardRef(({ children, onUpdate, className, defaultValue, ...props }, ref) => {
  const [state, setState] = useState(defaultValue);
  const [options, setOptions] = useState([]);
  const [wrapperProps, { name, ...inputProps }] = getWrapperProps(props);

  const pushOptions = (opts, optsProps) => {
    const selected = optsProps.value === defaultValue;

    opts.push({
      ...optsProps,
      labelKey: optsProps.value,
      value: optsProps.children,
      isDisabled: optsProps.disabled ? optsProps.disabled : false,
      isSelected: selected,
    });
    return opts;
  };

  useEffect(() => {
    if (!children) return;

    let opts = [];
    if (children.length > 0) {
      children.forEach((child) => {
        if (!child) return;

        opts = pushOptions(opts, child.props);
      });
    } else {
      opts = pushOptions(opts, children.props);
    }

    setOptions(opts);
  }, [defaultValue]);

  const handleChange = (e) => {
    if (!e.selectedKey.length) return;
    setState(e.selectedKey[0]);

    if (onUpdate) onUpdate(e.selectedKey[0]);
  };

  if (!options.length) return null;

  return (
    <FormWrapper {...wrapperProps}>
      <BootstrapSelect
        options={options}
        onChange={handleChange}
        className="old-w-100"
        selectStyle={className}
        data-role="form-error-component"
        ref={ref}
        {...inputProps}
      />
      <Form.Control name={name} value={state} hidden readOnly />
    </FormWrapper>
  );
});

FormSelect.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onUpdate: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormSelect.defaultProps = {
  onUpdate: undefined,
  className:
    "!bg-content2 !border-content2 !placeholder-primary-300 !text-foreground old-rounded-4 old-p-3 old-d-inline-block old-ms-after-n3",
  defaultValue: "",
};

export default FormSelect;
