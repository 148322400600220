import { DateProgressBar } from "ui";
import briefPropTypes from "features/brief/schemas/briefSchema";
import PropTypes from "prop-types";

export default function BriefTimeline({ brief, textClassName = "" }) {
  if (brief.type === "private") {
    return (
      <>
        <DateProgressBar
          startDate={brief.start_time}
          endDate={brief.proposal_deadline}
          title="Make a Proposal"
          textClassName={textClassName}
        />

        <DateProgressBar
          startDate={brief.proposal_selected}
          endDate={brief.submission_deadline}
          title="Submit Your Design"
          textClassName={textClassName}
        />

        <DateProgressBar
          startDate={brief.submission_deadline}
          endDate={brief.winners_selected}
          title="Final Review"
          textClassName={textClassName}
        />
      </>
    );
  }

  return (
    <>
      <DateProgressBar
        startDate={brief.start_time}
        endDate={brief.submission_deadline}
        title="Submit Your Design"
        textClassName={textClassName}
      />

      <DateProgressBar
        startDate={brief.submission_deadline}
        endDate={brief.voting_deadline}
        title="Voting"
        textClassName={textClassName}
      />
      <DateProgressBar
        startDate={brief.voting_deadline}
        endDate={brief.winners_selected}
        title="Winner Announced"
        textClassName={textClassName}
      />
    </>
  );
}
BriefTimeline.propTypes = {
  brief: briefPropTypes.isRequired,
  textClassName: PropTypes.string,
};
