import { useState, useEffect, useMemo, useContext } from "react";
import { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";
import { UserContext } from "features/user";
import { NavigationConfigContext } from "features/navigation";
import AuthContext from "features/authentication/context/AuthContext";
import AuthModal from "features/authentication/components/modal/AuthModal";
import PropTypes from "prop-types";
import Mixpanel from "mixpanel-browser";
import Sentry from "adapters/sentry";

export default function GlobalAuth({ children }) {
  const { user } = useContext(UserContext);
  const { config } = useContext(NavigationConfigContext);
  const [auth, setAuth] = useState({
    show: false,
    options: {},
  });

  const logout = async () => {
    await signOut();
    Mixpanel.reset();
    Sentry.setUser(null);
    window.location.href = config.redirectHome;
  };

  const showAuth = (options) => {
    // Redirect back to the page they were on
    const params = new URLSearchParams({
      redirectToPath: options?.redirectToPath || window.location.pathname,
    });
    window.history.replaceState("", "", `${window.location.pathname}?${params}`);

    setAuth({
      ...auth,
      show: true,
      options,
    });
  };

  const hideAuth = () => setAuth({ ...auth, show: false });

  useEffect(() => {
    // We don't want to show the modal until AFTER the return action
    if (localStorage.getItem("return-action")) return;

    const search = new URLSearchParams(window.location.search);
    if (Boolean(search.get("completeOnboarding")) === true && user.doesSessionExist)
      showAuth({ onboarding: true });
  }, [localStorage]);

  const authContextProviderValue = useMemo(
    () => ({ showAuth, hideAuth, logout }),
    [showAuth, hideAuth, logout],
  );

  return (
    <AuthContext.Provider value={authContextProviderValue}>
      {children}

      <AuthModal show={auth.show} onHide={hideAuth} {...auth.options} />
    </AuthContext.Provider>
  );
}

GlobalAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
